import axios from "axios";
import { NGROK } from "../../../APIs";
import UrlFetchManager from "../../../services/UrlFetchManager";

export const fetchNotifications = async (
  setter,
  fromdetails = false,
  computerName = null,
  email,
) => {
  try {
    const response = await UrlFetchManager(
      `${NGROK}/api/access/admin-notifications?email=${email}`,
    );
    let filteredData = response.data;
    if (fromdetails && computerName) {
      filteredData =
        response?.data && response?.data.length > 0
          ? response?.data.filter(
              (item) =>
                item?.computerName?.toLowerCase() ===
                computerName?.toLowerCase(),
            )
          : [];
    }
    setter(filteredData);
  } catch (error) {
    console.error(error);
  }
};
