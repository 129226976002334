import React, { useState, useEffect } from "react";

import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import MyHeaderTableCell from "./MyHeaderTableCell";
import StyledTooltip from "../../components/StyledTooltip";
import { useQuery } from "react-query";
import { getPublishers } from "../api";
import { myLocalStorage } from "../../../components/StorageHelper";

const ProfilePublishersTable = ({
  selectedProfile,
  selectedPublisher,
  setSelectedPublisher,
}) => {
  const [hideEmptyPublishers, setHideEmptyPublishers] = useState(true);
  const [publisherSearchValues, setPublisherSearchValues] = useState("");
  const [unsignedAppsPublisher, setUnsignedAppsPublisher] = useState(null);
  const allAppsPublisher = {
    id: "allAppsPublisher",
    frinedlyName: "allAppsPublisher",
  };

  const { data: publishers, isLoading } = useQuery({
    queryKey: ["publishers", selectedProfile.id],
    queryFn: () => getPublishers(selectedProfile),
    enabled: !!selectedProfile,
  });

  const filterPublishers = (publishers) => {
    if (publishers?.length) {
      const PublishersWithoutDuplicates = publishers
        .map((publisher) => ({ ...publisher, ids: [publisher.id] }))
        .reduce((acc, current) => {
          const x = acc.find(
            (publisher) => publisher.friendlyName === current.friendlyName
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            x.ids.push(current.id);
            return acc;
          }
        }, []);
      return hideEmptyPublishers
        ? PublishersWithoutDuplicates.filter((publisher) =>
            publisher.containsApps ? publisher : null
          )
        : PublishersWithoutDuplicates;
    }
  };

  const findPublisher = (e) => {
    setTimeout(() => {
      setPublisherSearchValues(e.target.value);
    }, 1500);
  };

  const filteredPublishers = filterPublishers(publishers)?.filter(
    (publisher) => {
      const isPublisherMatched =
        publisherSearchValues === "" ||
        publisher.friendlyName
          .toLowerCase()
          .includes(publisherSearchValues.toLowerCase());

      return isPublisherMatched && publisher.friendlyName !== "Unsigned Apps";
    }
  );

  const handleUnsignedApps = () => {
    setSelectedPublisher(unsignedAppsPublisher);
    myLocalStorage.setItem("lastSelectedPublisher", unsignedAppsPublisher);
  };

  const handleAllAppsPublisher = () => {
    setSelectedPublisher(allAppsPublisher);
    myLocalStorage.setItem("lastSelectedPublisher", allAppsPublisher);
  };

  useEffect(() => {
    if (publishers?.length) {
      const unsignedAppsPublisher = publishers?.find(
        (el) => el.friendlyName === "Unsigned Apps"
      );
      setUnsignedAppsPublisher(unsignedAppsPublisher);

      const latestPublisher = myLocalStorage.getItem("lastSelectedPublisher");
      const publisher = filterPublishers(publishers).find(
        (publisher) => publisher.id === latestPublisher?.id
      );

      if (publisher) setSelectedPublisher(publisher);
      else setSelectedPublisher(allAppsPublisher);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishers]);

  if (isLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <TableContainer
        style={{ height: "fit-content", minWidth: "300px" }}
        component={Paper}
      >
        {publishers?.length ? (
          <Table
            sx={{
              height: "fit-content",
              maxWidth: "100%",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <MyHeaderTableCell align={"center"} className={"userName"}>
                  Publisher and App groups
                </MyHeaderTableCell>
              </TableRow>
              {filterPublishers(publishers)?.length > 10 ? (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      sx={{ minWidth: "200px" }}
                      label="Find publisher ..."
                      id="searchForPublisher"
                      onChange={findPublisher}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={hideEmptyPublishers}
                    onChange={(e) => setHideEmptyPublishers(e.target.checked)}
                  />
                  <Typography fontSize={19}>Hide empty publishers</Typography>
                </TableCell>
              </TableRow>
              <TableRow
                className={
                  allAppsPublisher.id === selectedPublisher?.id
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                sx={{ border: 1 }}
                onClick={handleAllAppsPublisher}
              >
                <TableCell
                  scope="row"
                  className="userName"
                  component="td"
                  sx={{ marginBottom: "30px" }}
                >
                  All Apps
                </TableCell>
              </TableRow>
              {unsignedAppsPublisher ? (
                <TableRow
                  className={
                    unsignedAppsPublisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{ border: 1 }}
                  onClick={handleUnsignedApps}
                >
                  <TableCell
                    scope="row"
                    className="userName"
                    component="td"
                    sx={{ marginBottom: "30px" }}
                  >
                    Unsigned Apps
                  </TableCell>
                </TableRow>
              ) : null}
              {filteredPublishers.map((publisher) => (
                <TableRow
                  key={publisher.id}
                  className={
                    publisher.id === selectedPublisher?.id
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  sx={{
                    border: 1,
                  }}
                  onClick={() => {
                    setSelectedPublisher(publisher);
                    myLocalStorage.setItem("lastSelectedPublisher", publisher);
                  }}
                >
                  <StyledTooltip
                    arrow
                    title={
                      <>
                        <Typography color="inherit">
                          {`Subject: ${publisher.subject}`}
                        </Typography>
                        <br />
                        <Typography color="inherit">
                          {`Thumbprint: ${publisher.thumbprint}`}
                        </Typography>
                      </>
                    }
                    placement="left"
                  >
                    <TableCell
                      scope="row"
                      className="groupRowName userName"
                      component="td"
                    >
                      <>{publisher.friendlyName}</>
                    </TableCell>
                  </StyledTooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box pl={5}>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              There are no any publishers.
            </p>
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default ProfilePublishersTable;
