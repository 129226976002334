import { Navigate, useLocation } from "react-router-dom";
import { myLocalStorage } from "../StorageHelper";
import { useKeycloakStore } from "../../services/UserService";

const PrivateRoute = ({ element, access }) => {
  const { local_kc } = useKeycloakStore();
  const location = useLocation();
  const role = myLocalStorage.getItem("role");
  const access_token = myLocalStorage.getItem("access_token");
  const isAuthenticated = local_kc?.authenticated;

  if (isAuthenticated || access_token) {
    if (access.includes(role)) {
      return element;
    } else return <Navigate to="/404" state={{ from: location }} replace />;
  } else return <Navigate to="/signIn" state={{ from: location }} replace />;
};

export default PrivateRoute;
