import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { NGROK } from "../../../APIs";
import ResourcesTable from "./ResourcesTable";
import ResouceTypeTable from "./ResouceTypeTable";
import CategoriesTable from "./CategoriesTable";
import ".//../pages.scss";
import ExportImport from "./components/ExportImport";
import CreateCategoryDialog from "./components/CreateCategoryDialog";
import TenantSelection from "../Components/TenantSelection";
import OperationSystemTab from "./components/OperationSystemTab";
import { myLocalStorage } from "../../../components/StorageHelper";

const ResourceCategories = () => {
  const storageOS = myLocalStorage.getItem("selectedOS") || "WINDOWS";
  const tenant = myLocalStorage.getItem("latestTenant");

  const [osValue, setOsValue] = useState(storageOS);
  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [allSelectedResources, setAllSelectedResources] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedResourceType, setSelectedResourceType] = useState({
    name: "App",
    id: 1,
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [removedAppDuplicates, setRemovedAppDupicates] = useState([]);

  const getAllSelectedResources = async (resourceType, os) => {
    const newOS = os === "mac" ? "macos" : os;
    try {
      const response = await axios.get(
        `${NGROK}/api/computers/operation-system/${resourceType}?os=${newOS}&tenantName=${selectedTenant?.tenantName}`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const removeDuplicatesApps = (apps) => {
    const { uniqueApps, deletedApps } = apps?.reduce(
      (acc, current) => {
        const foundIndex = acc.uniqueApps.findIndex(
          (app) => app.path === current.path,
        );

        if (foundIndex === -1) {
          acc.uniqueApps.push(current);
        } else {
          acc.deletedApps.push(current);
        }

        return acc;
      },
      { uniqueApps: [], deletedApps: [] },
    );

    uniqueApps.sort((a, b) => a.id - b.id);
    deletedApps.sort((a, b) => a.id - b.id);

    return {
      uniqueApps,
      deletedApps,
    };
  };

  const fetchResources = async (os) => {
    switch (selectedResourceType.name) {
      case "App":
        const apps = await getAllSelectedResources("get-all-apps", os);

        const { uniqueApps, deletedApps } = removeDuplicatesApps(apps?.content);
        setRemovedAppDupicates(deletedApps);
        setAllSelectedResources(uniqueApps);
        break;
      case "Folder":
        const folders = await getAllSelectedResources("get-all-folders", os);
        folders.content.sort((a, b) => a.id - b.id);
        setAllSelectedResources(folders.content);
        break;
      case "Group":
        const privileges = await getAllSelectedResources("get-all-groups", os);
        privileges.content.sort((a, b) => a.id - b.id);
        setAllSelectedResources(privileges.content);
        break;
      case "Publisher":
        const publishers = await getAllSelectedResources(
          "get-all-publishers",
          os,
        );
        publishers.sort((a, b) => a.id - b.id);
        setAllSelectedResources(publishers);
        break;
      case "Url":
        const urls = await getAllSelectedResources("get-all-url-control", os);
        urls.content?.sort((a, b) => a.id - b.id);
        setAllSelectedResources(urls.content);
        break;
      default:
        console.log("default");
    }
  };

  const getCategories = async (resourceType) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/category/${resourceType}?tenantName=${selectedTenant?.tenantName}`,
      );
      return response.data;
    } catch {
      console.error("error during fetching categories");
    }
  };

  const fetchCategories = async (os) => {
    switch (selectedResourceType.name) {
      case "App":
        const appsCategories = await getCategories("for-apps");
        const appsCategoriesFilteredByOs = appsCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase(),
        );
        setCategoryList(appsCategoriesFilteredByOs);

        break;
      case "Folder":
        const folderCategories = await getCategories("for-folders");
        const foldersCategoriesFilteredByOs = folderCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase(),
        );
        setCategoryList(foldersCategoriesFilteredByOs);

        break;
      case "Group":
        const groupCategories = await getCategories("for-groups");
        const groupsCategoriesFilteredByOs = groupCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase(),
        );
        setCategoryList(groupsCategoriesFilteredByOs);

        break;
      case "Publisher":
        const publisherCategories = await getCategories("for-publishers");
        const publisherCategoriesFilteredByOs = publisherCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase(),
        );
        setCategoryList(publisherCategoriesFilteredByOs);

        break;
      case "Url":
        const urlControlCategories = await getCategories("for-url-control");
        const urlCategoriesFilteredByOs = urlControlCategories?.filter(
          (category) =>
            category.operationSystem.toUpperCase() === os.toUpperCase(),
        );
        setCategoryList(urlCategoriesFilteredByOs);
        break;
      default:
        console.log("default");
    }
  };

  const categoriesTableProps = {
    setAllSelectedResources,
    fetchResources,
    os: osValue,
    allSelectedResources,
    selectedTenantName: selectedTenant?.tenantName,
    resourceType: selectedResourceType.name,
    selectedCategory,
    setSelectedCategory,
    fetchCategories,
    categoryList,
    setCategoryList,
    removedAppDuplicates,
  };

  const resourceTableProps = {
    fetchResources,
    allSelectedResources,
    setAllSelectedResources,
    resourceType: selectedResourceType.name,
    selectedTenantName: selectedTenant?.tenantName,
    selectedCategory,
    os: osValue,
  };

  const updateTheResource = (val) => {
    setCategoryList([]);
    setSelectedResourceType(val);
  };

  useEffect(() => {
    setSelectedCategory(null);
  }, [selectedResourceType, osValue]);

  return (
    <Box minWidth={800}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
        <Box display={"flex"} justifyContent={"space-between"} width={355}>
          <ExportImport
            tenantName={selectedTenant?.tenantName}
            os={osValue}
            fetchCategories={fetchCategories}
            resourceType={selectedResourceType?.name}
            categoryList={categoryList}
          />
          <CreateCategoryDialog
            selectedTenantName={selectedTenant?.tenantName}
            setSelectedCategory={setSelectedCategory}
            os={osValue}
            fetchCategories={fetchCategories}
            resourceType={selectedResourceType?.name}
            allSelectedResources={allSelectedResources}
            removedAppDuplicates={removedAppDuplicates}
            categoryList={categoryList}
          />
        </Box>
      </Box>

      <OperationSystemTab
        osValue={osValue}
        setOsValue={setOsValue}
        windowsContent={
          <Box display={"flex"} width={"100%"} gap={3} sx={{ marginTop: 5 }}>
            <Box flex={"0 1 200"} sx={{ marginTop: 5.5 }}>
              <ResouceTypeTable
                selectedResource={selectedResourceType}
                updateTheResource={updateTheResource}
              />
            </Box>

            <Box
              flex={"1 2 0"}
              minWidth={350}
              maxWidth={600}
              sx={{ marginTop: 3.3 }}
            >
              <CategoriesTable {...categoriesTableProps} os={"WINDOWS"} />
            </Box>
            <Box flex={"1 1 0"} minWidth={500}>
              <ResourcesTable {...resourceTableProps} os={"WINDOWS"} />
            </Box>
          </Box>
        }
        linuxContent={
          <Box display={"flex"} width={"100%"} gap={3} sx={{ marginTop: 5.5 }}>
            <Box flex={"0 1 200"} sx={{ marginTop: 5.5 }}>
              <ResouceTypeTable
                selectedResource={selectedResourceType}
                setSelectedResource={setSelectedResourceType}
                updateTheResource={updateTheResource}
              />
            </Box>
            <Box
              flex={"1 2 0"}
              minWidth={350}
              maxWidth={600}
              sx={{ marginTop: 3.3 }}
            >
              <CategoriesTable {...categoriesTableProps} os={"LINUX"} />
            </Box>

            <Box flex={"1 1 0"} minWidth={500}>
              <ResourcesTable {...resourceTableProps} os={"LINUX"} />
            </Box>
          </Box>
        }
        macContent={
          <Box display={"flex"} width={"100%"} gap={3} sx={{ marginTop: 5.5 }}>
            <Box flex={"0 1 200"} sx={{ marginTop: 5.5 }}>
              <ResouceTypeTable
                selectedResource={selectedResourceType}
                setSelectedResource={setSelectedResourceType}
                updateTheResource={updateTheResource}
              />
            </Box>
            <Box
              flex={"1 2 0"}
              minWidth={350}
              maxWidth={600}
              sx={{ marginTop: 3.3 }}
            >
              <CategoriesTable {...categoriesTableProps} os={"MACOS"} />
            </Box>

            <Box flex={"1 1 0"} minWidth={500}>
              <ResourcesTable {...resourceTableProps} os={"MACOS"} />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default ResourceCategories;
