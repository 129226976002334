import jsPDF from "jspdf";
import moment from "moment";
import Logo from "../../../vendor/Whiteswan-Security-logo.png";
import autoTable from "jspdf-autotable";


export const handleGeneratePDF = async (dateRange, notifications, searchTerm) => {
    const pdf = new jsPDF();
    const title = "Request Access";
    const titleWidth = pdf.getTextWidth(title);
    const pageWidth = pdf.getPageWidth();
    const titleX = (pageWidth - titleWidth) / 2;

    const startDate = dateRange[0]
      ? moment(dateRange[0]).format("DD.MM.YYYY")
      : "-";
    const endDate = dateRange[1]
      ? moment(dateRange[1]).format("DD.MM.YYYY")
      : "-";

    const imgX = (pageWidth - 100) / 2;

    pdf.setFillColor(35, 48, 68);
    pdf.rect(0, 0, pdf.internal.pageSize.width, 40, "F");

    pdf.addImage(Logo, "png", imgX, 5, 100, 30);

    const startNewPage = () => {
      if (pdf) pdf.addPage();
    };

    const computerNames = [
      ...new Set(
        notifications.map(
          (notification) => notification.computerName
        )
      ),
    ];

    computerNames.forEach((computerName, index) => {
      let startY = index === 0 ? 50 : 10;

      const computerNotifications = notifications.filter(
        (notification) => notification.computerName === computerName
      );
      pdf.text("Request Access", titleX, startY);
      pdf.text(`Computer Name: ${computerName}`, 10, startY + 10);
      startDate && pdf.text(`Start Date: ${startDate}`, 10, startY + 20);
      endDate && pdf.text(`End Date: ${endDate}`, 10, startY + 30);
      pdf.text(`Search Term: "${searchTerm || "-"}"`, 10, startY + 40);

      const tableBody = [
        ...computerNotifications.map((notification, index) => [
          index + 1,
          moment(notification.createdAt).format("DD.MM.YYYY HH:mm"),
          notification.email,
          notification.resourceType,
          notification.resourceName,
          notification.requestMessage,
          notification.privilegeLevel,
          `${notification.selectedTime} min`,
          notification.requestStatus,
        ]),
      ];

      autoTable(pdf, {
        startY: startY + 50,
        headStyles: { fillColor: [35, 48, 68], halign: "center" },
        head: [
          [
            "Sl No",
            "Created At",
            "Requested By",
            "Category",
            "Resource Name",
            "Justification",
            "Privilege Level",
            "Time",
            "Status",
          ],
        ],
        body: tableBody,
        bodyStyles: { halign: "center" },

        autoSize: false,
        margin: { top: 5, right: 0, bottom: 0, left: 0 },
        columnStyles: {
          0: { cellWidth: 10, halign: "center" },
          1: { cellWidth: 20, halign: "center" },
          2: { cellWidth: 30, halign: "center" },
          5: { cellWidth: 50, halign: "center" },
        },
      });
      startNewPage();
    });

    pdf.save("Reports.pdf");
  };