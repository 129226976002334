import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";

import "./DomainTable.scss";

const SpinerContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
`;

const Tr = styled.tr`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 2fr 3fr 2fr;
  @media (max-width: 768px) {
    display: inherit;
  }
`;

const Th = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 40px;
`;

const Td = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 40px;
`;
const TdLast = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const Table = styled.table`
  margin: 20px 0px; ;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DomainTable = ({ serverData }) => {
  console.log("data", serverData);

  if (serverData === undefined && serverData.length === 0)
    return (
      <SpinerContainer>
        <CircularProgress />
      </SpinerContainer>
    );

  return (
    <Table className="domainTable">
      <thead>
        <Tr>
          <Th align="center">Domain</Th>
          <Th align="center">Server</Th>
          <Th align="center">Port</Th>
          <Th align="center">SSL</Th>
          <Th align="center">Last Sync</Th>
          <Th align="center">Status</Th>
          <Th align="center">Default Policy</Th>
        </Tr>
      </thead>
      <tbody>
        {/* {data.map((domain) => ( */}
        <Tr>
          <Td align="left" className="tdIconSize">
            <span>{serverData.domainName}</span>
          </Td>
          <Td align="center" className="tdIconSize">
            {serverData.ldapServer}
          </Td>
          <Td align="center" className="tdIconSize">
            {serverData.port}
          </Td>
          <Td align="center" className="tdIconSize sslStatus">
            <ClearIcon />
          </Td>
          <Td align="center" className="tdIconSize lastSync">
            <FlexDiv>in 5 minutes</FlexDiv>
          </Td>
          <Td align="left" className="tdIconSize syncClass">
            <CheckCircleOutlineOutlinedIcon /> Synced
          </Td>
          <TdLast align="center" className="tdIconSize">
            <FlexDiv className="scanIsonClass">
              <GppGoodOutlinedIcon /> Scan
            </FlexDiv>
            <FlexDiv className="warnIsonClass">
              <WarningAmberOutlinedIcon /> Product
            </FlexDiv>
          </TdLast>
        </Tr>
        {/* ))} */}
      </tbody>
    </Table>
  );
};

export default DomainTable;
