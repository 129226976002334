import React, { useEffect } from "react";

import {
  Button,
  TableBody,
  TableCell,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import RequestMessageDialog from "../../../components/RequestMessageDialog";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import { fetchNotifications } from "../api";

const RequestsTableBody = ({
  adminNotifications,
  setAdminNotifications,
  fromdetails,
  computerName,
}) => {
  const userData = useUserStore((state) => state.user);
  const handleRequest = async (id, status, fromdetails, computerName) => {
    await axios.put(`${NGROK}/api/access/update`, {
      accessId: id,
      requestStatus: status,
    });
    setTimeout(() => {
      fetchNotifications(
        setAdminNotifications,
        fromdetails,
        computerName,
        userData.email,
      );
    }, 500);
  };

  const updateGroupStatus = async (notification) => {
    const groupStatus = notification.selectedTime ? "DYNAMIC" : "ENABLED";

    if (notification.resourceType === "GROUP") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user-groups/membership?computerId=${notification.computerId}`,
        {
          userId: notification.userId,
          groupId: notification.resourceId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: notification.email,
          role: userData.role,
          fromAccessRequest: true,
        },
      );
    } else if (notification.resourceType === "FOLDER") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user/folders/timer`,
        {
          userId: notification.userId,
          folderId: notification.resourceId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: userData.email,
          role: userData.role,
          fromAccessRequest: true,
          computerId: notification.computerId,
        },
      );
    } else if (notification.resourceType === "APP") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user/publishers/apps/timer`,
        {
          userId: notification.userId,
          appId: notification.resourceId,
          publisherId: notification.publisherId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: userData.email,
          role: userData.role,
          fromAccessRequest: true,
        },
      );
    } else if (notification.resourceType === "URL") {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/computer-user/url-control/timer`,
        {
          userId: notification.userId,
          urlId: notification.resourceId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
          email: userData.email,
          role: userData.role,
          fromAccessRequest: true,
          computerId: notification.computerId,
        },
      );
    }
  };

  const updateWhiteswanAccessGroup = async (notification) => {
    const groupStatus = notification.selectedTime ? "DYNAMIC" : "ENABLED";

    try {
      await axios.put(
        `${NGROK}/api/${notification.tenantName}/whiteswan-access`,
        {
          userId: notification.userId,
          computerId: notification.computerId,
          groupStatus,
          selectedTime: notification.selectedTime * 60,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const submitUpdateGroupStatus = async (notification) => {
    if (notification.isWhiteswanAccess)
      await updateWhiteswanAccessGroup(notification);
    else await updateGroupStatus(notification);
  };

  useEffect(() => {
    fetchNotifications(
      setAdminNotifications,
      fromdetails,
      computerName,
      userData.email,
    );
  }, [fromdetails, computerName]);

  return (
    <TableBody>
      {adminNotifications.length
        ? adminNotifications.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {moment(notification.createdAt).format("YYYY/MM/DD/HH:mm")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.email}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.computerName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.resourceType === "GROUP" &&
                  notification.resourceName === "Whiteswan Access"
                    ? "SESSION"
                    : notification.resourceType}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  maxWidth: "350px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.resourceName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
              >
                <RequestMessageDialog
                  messageText={notification.requestMessage}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                {notification.privilegeLevel !== "DYNAMIC" ? (
                  <Typography
                    fontWeight={500}
                    fontSize={16}
                    variant="subtitle2"
                  >
                    PERMANENT
                  </Typography>
                ) : (
                  <Stack spacing={2}>
                    <Typography
                      fontWeight={500}
                      fontSize={16}
                      variant="subtitle2"
                    >
                      DYNAMIC
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={16}
                      variant="subtitle2"
                    >
                      {notification.selectedTime} min
                    </Typography>
                  </Stack>
                )}
              </TableCell>
              {notification.requestStatus === "PENDING" ? (
                <>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    <Button
                      onClick={() => {
                        handleRequest(
                          notification.id,
                          "APPROVED",
                          fromdetails,
                          computerName,
                        );
                        submitUpdateGroupStatus(notification);
                      }}
                      variant="outlined"
                    >
                      Approve
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    <Button
                      onClick={() => {
                        handleRequest(
                          notification.id,
                          "DECLINE",
                          fromdetails,
                          computerName,
                        );
                      }}
                      color="error"
                      variant="outlined"
                    >
                      Decline
                    </Button>
                  </TableCell>
                </>
              ) : notification.requestStatus === "APPROVED" ? (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="success" variant="outlined">
                    APPROVED
                  </Button>
                </TableCell>
              ) : notification.requestStatus === "DECLINE" ? (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="error" variant="outlined">
                    DECLINED
                  </Button>
                </TableCell>
              ) : (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="error" variant="outlined">
                    EXPIRED
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))
        : null}
    </TableBody>
  );
};

export default RequestsTableBody;
