import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Stack,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Alert,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { NGROK } from "../../../../APIs";
import MatchedResourceTable from "./MatchedResourceTable";
import useUserStore from "../../../../services/userStore";
import {
  createCategoryPatternRegex,
  getResourceNameField,
} from "../../../../services/Helpers";
import useDebouncedValue from "../../../../hooks/useDebouncedValue";
import PatternsBox from "../../Components/PatternsBox";

const EditCategoryDialog = ({
  allSelectedResources,
  category,
  resourceType,
  os,
  fetchResources,
  fetchCategories,
  selectedTenantName,
  selectedCategory,
  removedAppDuplicates,
  categoriesNames,
}) => {
  const { email } = useUserStore((state) => state.user);

  const [newCategory, setNewCategory] = useState("");
  const [categoryPriority, setCategoryPriority] = useState(
    category.reputationScore
  );
  const [matchedResources, setMatchedResources] = useState([]);
  const [searchPatternList, setSearchPatternList] = useState([]);
  const [patternInputText, setPatternInputText] = useState("");
  const [dialogToShow, setDialogToShow] = useState(null);
  const [manualExclusions, setManualExclusions] = useState(
    category.manualExclusions
  );
  const [activeChip, setActiveChip] = useState(null);
  const [categoryNameError, setCategoryNameError] = useState("");
  const debouncedSearchTerm = useDebouncedValue(patternInputText, 1000);

  const resourceNameField = getResourceNameField(resourceType);

  const getAppManualExclusions = (removedAppDuplicates, manualExclusions) => {
    return removedAppDuplicates
      ?.map((app) => ({
        id: app.id,
        name: app.path,
      }))
      .filter((exclusion) =>
        manualExclusions.some(
          (manualExclusion) => manualExclusion.name === exclusion.name
        )
      );
  };

  const checkMatches = (newPattern) => {
    if (newPattern === "*") {
      setMatchedResources(allSelectedResources);
      return;
    } else if (!newPattern && !activeChip) {
      setMatchedResources([]);
      return;
    } else if (newPattern) {
      try {
        const regex =
          os === "WINDOWS"
            ? createCategoryPatternRegex(newPattern, true)
            : createCategoryPatternRegex(newPattern);

        const filteredResources = allSelectedResources.filter((resource) =>
          resource[resourceNameField].match(regex)
        );

        setMatchedResources(filteredResources);
      } catch (error) {
        console.error("Invalid regular expression:", error.message);
      }
    }
  };

  const handleDeletePattern = (id) => {
    const updatedPatternList = searchPatternList.filter(
      (field) => field.id !== id
    );
    setSearchPatternList(updatedPatternList);
  };

  const addPatternToCategory = (text, id) => {
    const updatedPatternList = [...searchPatternList, { id, text }];
    setSearchPatternList(updatedPatternList);
    setPatternInputText("");
  };

  const closeAndCleanDialog = () => {
    setDialogToShow(null);
    setMatchedResources([]);
    setSearchPatternList([]);
    setPatternInputText("");
    setCategoryNameError("");
    setActiveChip(null);
  };

  const cancelDialog = () => {
    setManualExclusions(category.manualExclusions);
    closeAndCleanDialog();
  };

  const updateCategory = async () => {
    const newOs = os === "mac" ? "macos" : os;
    const exclusionsDupplicates = getAppManualExclusions(
      removedAppDuplicates,
      manualExclusions
    );
    const newManualExclusions =
      resourceType !== "App"
        ? manualExclusions
        : [...manualExclusions, ...exclusionsDupplicates];

    await axios.put(`${NGROK}/api/category`, {
      newName: newCategory,
      reputationScore: categoryPriority,
      newSearchPatterns: searchPatternList,
      oldName: category.name,
      oldSearchPatterns: category.searchPatterns,
      os: newOs,
      resourceType: resourceType.toUpperCase(),
      userEmail: email,
      manualExclusions: newManualExclusions,
      tenantName: selectedTenantName,
    });
  };

  const getAllMatchedResources = (newPatterns) => {
    return allSelectedResources.filter((resource) => {
      return newPatterns.some((pattern) => {
        const regex =
          os === "WINDOWS"
            ? createCategoryPatternRegex(pattern.text, true)
            : createCategoryPatternRegex(pattern.text);
        return resource[resourceNameField].match(regex);
      });
    });
  };

  const handleSubmit = () => {
    const isCategoryAlreadyExist =
      categoriesNames
        .map((category) => category.toLowerCase())
        .includes(newCategory.toLowerCase()) &&
      newCategory.toLowerCase() !== category.name.toLowerCase();

    setCategoryNameError("Category with this name already exists.");
    if (isCategoryAlreadyExist) return;

    updateCategory();

    setTimeout(() => {
      fetchCategories(os);
      fetchResources(os);
    }, 1500);
    closeAndCleanDialog();
  };

  useEffect(() => {
    if (category.hasOwnProperty("searchPatterns")) {
      const oldPatterns = category.searchPatterns.map((pattern) => ({
        id: uuidv4(),
        text: pattern,
      }));
      setSearchPatternList(oldPatterns);
    } else {
      setSearchPatternList([]);
    }
  }, [category, dialogToShow]);

  useEffect(() => {
    if (category.hasOwnProperty("name")) {
      setNewCategory(category.name);
    }
  }, [category]);

  const handlePriority = (event) => {
    const inputValue = event.target.value;

    const regex = /^\d{0,100}$/;

    if (regex.test(inputValue)) {
      setCategoryPriority(inputValue);
    }
  };

  const handleCategoryNameInput = (newCategoryName) => {
    setCategoryNameError("");

    setNewCategory(newCategoryName);
  };

  const handleAddPattern = () => {
    const id = uuidv4();
    addPatternToCategory(patternInputText, id);
    setActiveChip({ id, text: patternInputText });
  };

  const handleChip = (textfield) => {
    setPatternInputText("");
    checkMatches(textfield.text);
    setActiveChip(textfield);
  };

  const handleAllMatchesChip = () => {
    setPatternInputText("");
    setActiveChip({ id: 999, text: "ALL" });
    setMatchedResources(getAllMatchedResources(searchPatternList));
  };

  const isSubmitButtonEnabled = () => {
    if (
      newCategory &&
      !(newCategory.trim() === "") &&
      searchPatternList.length &&
      categoryPriority
    )
      return true;
    else return false;
  };

  const handlePatternInputChange = (e) => {
    setPatternInputText(e.target.value);
    setActiveChip(null);
  };

  const patternProps = {
    handlePatternInputChange,
    handleAddPattern,
    handleAllMatchesChip,
    handleChip,
    handleDeletePattern,
    patternInputText,
    matchedResources,
    searchPatternList,
    activeChip,
  };

  useEffect(() => {
    checkMatches(debouncedSearchTerm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <Button
        sx={category.name === selectedCategory?.name ? { color: "white" } : {}}
        variant="outlined"
        color="primary"
        onClick={() => {
          setDialogToShow(category.name);
        }}
      >
        Edit
      </Button>
      <Dialog
        open={dialogToShow === category.name}
        onClose={cancelDialog}
        fullWidth
      >
        <DialogTitle>Fill required fields</DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <Box
              height={60}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <TextField
                required
                autoFocus
                id="name"
                label="Category Name"
                type="text"
                variant="outlined"
                defaultValue={category.name}
                onChange={(e) => handleCategoryNameInput(e.target.value)}
                sx={{ height: 45, width: "70%" }}
              />
              <Tooltip
                disableFocusListener
                title="If a resource evaluates to multiple categories, it will be assigned the category with highest priority."
                // enterDelay={1500}
                leaveDelay={200}
              >
                <TextField
                  required
                  autoFocus
                  id="name"
                  label="Priority"
                  type="number"
                  variant="outlined"
                  defaultValue={category.reputationScore}
                  onChange={handlePriority}
                  sx={{ height: 45, width: "25%" }}
                />
              </Tooltip>
            </Box>

            {categoryNameError ? (
              <Alert sx={{ marginTop: 1 }} severity="error">
                {categoryNameError}
              </Alert>
            ) : null}

            <PatternsBox {...patternProps} />

            {matchedResources.length ? (
              <Box component={"fieldset"} borderRadius={1}>
                <legend>Matched Resources</legend>
                <MatchedResourceTable
                  activeChip={activeChip}
                  manualExclusions={manualExclusions}
                  setManualExclusions={setManualExclusions}
                  matchedResources={matchedResources}
                  resourceNameField={resourceNameField}
                />
              </Box>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={cancelDialog}>
            Cancel
          </Button>
          <Button
            disabled={!isSubmitButtonEnabled()}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCategoryDialog;
