import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const TimerForAWS = ({
  accessTimeOverAt,
  resourceId,
  setTimedResourcesWithoutAccess,
}) => {
  const calculateTimeLeft = () => {
    const now = dayjs.utc();
    const timeOverAt = dayjs.utc(accessTimeOverAt);
    const differenceInSeconds = timeOverAt.diff(now, "second");
    return differenceInSeconds > 0 ? differenceInSeconds : 0;
  };

  const [secondsLeft, setSecondsLeft] = useState(calculateTimeLeft());

  const convertSecondsToHoursAndMinutes = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (secondsLeft > 0) {
      const interval = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds <= 1) {
            setTimedResourcesWithoutAccess((prev) => {
              const ids = prev.includes(resourceId)
                ? prev
                : [...prev, resourceId];
              return ids;
            });
            clearInterval(interval);
            return 0;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [secondsLeft, resourceId, setTimedResourcesWithoutAccess]);

  return (
    <div className="timers">
      <div className="text">Time left</div>
      <div className="time">
        <span>{convertSecondsToHoursAndMinutes(secondsLeft)}</span>
      </div>
    </div>
  );
};

export default TimerForAWS;
