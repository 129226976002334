import React from "react";
import ResourceRequests from "../../ResourceRequests/ResourceRequests";

const DeviceResourceRequest = ({ device }) => {
  return (
    <>
      <ResourceRequests fromdetails={true} computerName={device?.dNSHostName} />
    </>
  );
};

export default DeviceResourceRequest;
