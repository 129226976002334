import React, { useState, useEffect } from "react";

const Timer = ({ seconds, resourceId, setTimedResourcesWithoutAccess }) => {
  const [min, setMin] = useState(Number(Math.ceil(seconds / 60)));
  const [sec, setSec] = useState(Number(Math.ceil(seconds % 60)));

  function convertMinutesToHoursAndMinutes(min) {
    const totalSeconds = Math.ceil(min * 60);
    const roundedMinutes = Math.ceil(totalSeconds / 60);

    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    const formattedTime = `${hours}:${String(minutes).padStart(2, "0")}`;
    return formattedTime;
  }

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          setTimedResourcesWithoutAccess((prev) => {
            const ids = [...prev].includes(resourceId)
              ? [...prev]
              : [...prev, resourceId];
            return ids;
          });
          clearInterval(myInterval);
        } else {
          setMin(min - 1);
          setSec(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sec]);

  return (
    <div className="timers">
      <div className="text">hours left</div>
      <div className="time">
        <span>{convertMinutesToHoursAndMinutes(min)}</span>
        {/* <span>{sec < 10 ? <>{"0" + sec}</> : <>{sec}</>}</span> */}
      </div>
    </div>
  );
};

export default Timer;
