import React from "react";
import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { getComputers } from "../../Endpoints/api";

const ComputerItem = ({ device, selectedTenant }) => {
  const navigate = useNavigate();

  const operatingSystems = {
    linux: "LINUX",
    windows: "WINDOWS",
    mac: "MAC",
  };

  const os = Object.keys(operatingSystems)?.find((key) =>
    device.OperatingSystem?.toLowerCase().includes(key)
  );

  const osIcon = {
    linux: <FaLinux size={40} color={"#233044"} />,
    windows: <FaWindows size={35} color={"#233044"} />,
    mac: <FaApple size={40} color={"#233044"} />,
  };

  const typographyHeaderStyles = {
    fontSize: "16px",
    display: "inline",
    fontWeight: 600,
  };

  const typographyBodyStyles = { fontSize: "16px", display: "inline" };

  const icon = os ? osIcon[os] : osIcon["windows"];

  const handleDeviceDeviceClick = async () => {
    const response = await getComputers(selectedTenant?.tenantName);
    const endpointsComputers = response || [];

    const selectedDevice = endpointsComputers?.find(
      (computer) => computer.dNSHostName === device.DNSHostName
    );

    if (selectedDevice) {
      myLocalStorage.setItem("activeComputer", selectedDevice);
      navigate("/endpoints/resources", {
        state: {
          selectedTenant,
          activeComputer: { ...device, id: device.endpointId },
        },
      });
    }
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: "white" }}>{icon}</Avatar>
      </ListItemAvatar>
      <Stack spacing={1}>
        <ListItemText
          primary={
            <Stack spacing={1} component={"span"}>
              <Stack
                direction={"row"}
                spacing={2}
                display={"flex"}
                alignItems={"center"}
              >
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"Name: "}
                </Typography>
                {device.hasAgent ? (
                  <Button variant="text" onClick={handleDeviceDeviceClick}>
                    <Typography component="span" sx={typographyBodyStyles}>
                      {device.Name}
                    </Typography>
                  </Button>
                ) : (
                  <Typography component="span" sx={typographyBodyStyles}>
                    {device.Name}
                  </Typography>
                )}
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"OS: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {device.OperatingSystem}
                </Typography>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"DNSHostName: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {device.DNSHostName}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"DistinguishedName: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {device.DistinguishedName}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography component="span" sx={typographyHeaderStyles}>
                  {"IP: "}
                </Typography>
                <Typography component="span" sx={typographyBodyStyles}>
                  {device.IPv4Address}
                </Typography>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </ListItem>
  );
};

export default ComputerItem;
