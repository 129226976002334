import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const EndpointsTableHead = ({
  role,
  isEveryMachinesSelected,
  selectAllMachines,
  userRole,
}) => {
  return (
    <TableHead>
      <TableRow>
        {role !== "TENANT_USER" ? (
          <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
            {/* <Checkbox
            checked={isEveryMachinesSelected}
            onChange={selectAllMachines}
            size="small"
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
            }}
            inputProps={{ "aria-label": "controlled" }}
          /> */}
          </TableCell>
        ) : null}
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Device
        </TableCell>
        {role !== "TENANT_USER" ? (
          <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
            Group
          </TableCell>
        ) : null}

        {role !== "TENANT_USER" ? (
          <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
            IP
          </TableCell>
        ) : null}
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Profile
        </TableCell>
        <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
          Operating Mode
        </TableCell>
        {role !== "TENANT_USER" ? (
          <TableCell sx={{ color: "white", fontSize: "16px" }} align="center">
            Management
          </TableCell>
        ) : null}
        {userRole !== "epam-user" ? (
          <TableCell
            colSpan={1}
            sx={{ color: "white", fontSize: "16px" }}
            align="center"
          >
            Access
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

export default EndpointsTableHead;
