import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { rgba, darken } from "polished";

import { Badge, Collapse, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useUserStore from "../../services/userStore";
import useNotificationStore from "../../services/notificationStore";
import { myLocalStorage } from "../StorageHelper";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1,
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

// const Badge = styled(Chip)`
//   font-weight: ${(props) => props.theme.typography.fontWeightBold};
//   height: 20px;
//   position: absolute;
//   right: 26px;
//   top: 12px;
//   background: ${(props) => props.theme.sidebar.badge.background};
//   z-index: 1;
//   span.MuiChip-label,
//   span.MuiChip-label:hover {
//     font-size: 11px;
//     cursor: pointer;
//     color: ${(props) => props.theme.sidebar.badge.color};
//     padding-left: ${(props) => props.theme.spacing(2)};
//     padding-right: ${(props) => props.theme.spacing(2)};
//   }
// `;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);
  const { userNotifications, adminNotifications } = useNotificationStore();
  const { role, email } = useUserStore((state) => state.user);

  const [notificationsCount, setNotificationsCount] = useState(0);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  const getNotificationsCount = (notifications) => {
    if (!notifications.length) return;
    else {
      return notifications.filter(
        (notification) => notification.requestStatus === "PENDING",
      ).length;
    }
  };

  useEffect(() => {
    if (role === "TENANT_USER" && userNotifications.length) {
      const loginedUserNotifications = userNotifications?.filter(
        (notification) => notification.email === email,
      );
      if (loginedUserNotifications?.length) {
        const count = getNotificationsCount(userNotifications);
        setNotificationsCount(count);
      }
    } else {
      if (adminNotifications.length) {
        const adminNotificationTenantFilter = adminNotifications?.filter(
          (adminNotification) => adminNotification.tenantName === latestTenant,
        );
        const count = getNotificationsCount(adminNotificationTenantFilter);
        setNotificationsCount(count);
      }
    }
  }, [adminNotifications, userNotifications, role, email, latestTenant]);

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {badge && notificationsCount ? (
            <Badge badgeContent={notificationsCount} color="secondary">
              {Icon && <Icon />}
            </Badge>
          ) : (
            Icon && <Icon />
          )}
          <Title depth={depth}>{title}</Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
      >
        {badge && notificationsCount ? (
          <Badge badgeContent={notificationsCount} color="secondary">
            {Icon && <Icon />}
          </Badge>
        ) : (
          Icon && <Icon />
        )}
        <Title depth={depth}>{title}</Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
