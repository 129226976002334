import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import useDebouncedValue from "../../../../hooks/useDebouncedValue";
import { v4 as uuidv4 } from "uuid";
import { createDeviceGroupPatternRegex } from "../../../../services/Helpers";
import MatchedResourceTable from "../../ResourceCategories/components/MatchedResourceTable";
import ProfileSelection from "../../Profiles/components/ProfileSelection";
import {
  createNewDeviceGroup,
  createNewDeviceGroupAsOu,
  editDeviceGroup,
  editOuGroup,
  fetchDeviceGroups,
  fetchDevices,
} from "../api";
import RadioSelection from "./RadioSelection";
import AddPatternForm from "./AddPatternForm";
import OuSelection from "./OuSelection";
import MatchedDevicesTable from "./MatchedDevicesTable";

const DeviceGroupDialog = ({
  deviceList,
  setDeviceList,
  os,
  selectedGroup,
  profilesList,
  tenantName,
  setDeviceGroups,
}) => {
  const [deviceGroupName, setDeviceGroupName] = useState("");
  const [groupPriority, setGroupPriority] = useState(10);
  const [matchedDevices, setMatchedDevices] = useState([]);
  const [patternInputText, setPatternInputText] = useState("");
  const [searchPatternList, setSearchPatternList] = useState([]);
  const [manualExclusions, setManualExclusions] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeChip, setActiveChip] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [radioValue, setRadioValue] = useState("TAG");
  const [selectedOU, setSelectedOU] = useState(null);
  const [ouDevices, setOuDevices] = useState([]);

  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);

  const debouncedSearchTerm = useDebouncedValue(patternInputText, 1000);

  const handleAddTag = () => {
    if (tag && !tags.includes(tag)) {
      setTags((prevTags) => [...prevTags, tag]);
      setTag("");
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddTag();
    }
  };

  const checkMatches = (patternText) => {
    if (patternText === "*") {
      setMatchedDevices(deviceList);
      return;
    } else if (!patternText && !activeChip) {
      setMatchedDevices([]);
      return;
    } else if (patternText) {
      try {
        const regex = createDeviceGroupPatternRegex(patternText);

        const filteredDevices = deviceList.filter((device) =>
          device.dNSHostName.match(regex),
        );

        setMatchedDevices(filteredDevices);
      } catch (error) {
        console.error("Invalid regular expression:", error.message);
      }
    }
  };

  const handleDeletePattern = (id) => {
    const newPatternListFiels = searchPatternList.filter(
      (field) => field.id !== id,
    );
    setSearchPatternList(newPatternListFiels);
  };

  const addPatternToGroup = (text, id) => {
    const updatedPatternList = [...searchPatternList, { id, text }];
    setSearchPatternList(updatedPatternList);
    setPatternInputText("");
  };

  const closeAndCleanDialog = () => {
    setIsDialogOpen(false);
    setMatchedDevices([]);
    setSearchPatternList([]);
    setPatternInputText("");
    setActiveChip(null);
    setDeviceGroupName("");
    setSelectedOU(null);
    setOuDevices([]);
    setRadioValue("PATTERNS");
  };

  const handleSubmit = async () => {
    selectedGroup
      ? editDeviceGroup(
          selectedGroup.uuid,
          selectedGroup.name,
          deviceGroupName,
          tenantName,
          os,
          selectedProfile.id,
          selectedProfile.name,
          groupPriority,
          selectedGroup.searchPatterns,
          searchPatternList,
          manualExclusions,
          tags,
        )
      : createNewDeviceGroup(
          deviceGroupName,
          tenantName,
          os,
          selectedProfile.id,
          selectedProfile.name,
          groupPriority,
          searchPatternList,
          manualExclusions,
          tags,
        );

    setTimeout(() => {
      const fetchData = async () => {
        const groups = await fetchDeviceGroups(tenantName, os);
        const devices = await fetchDevices(tenantName, os);

        setDeviceGroups(groups);
        setDeviceList(devices);
      };

      fetchData();
    }, 1000);
    closeAndCleanDialog();
  };

  const handleSubmitAsTags = () => {
    selectedGroup
      ? editOuGroup(
          selectedGroup.uuid,
          selectedGroup.name,
          deviceGroupName,
          tenantName,
          os,
          selectedProfile.id,
          selectedProfile.name,
          groupPriority,
          null,
          [],
          tags,
        )
      : createNewDeviceGroupAsOu(
          deviceGroupName,
          tenantName,
          os,
          selectedProfile.id,
          selectedProfile.name,
          groupPriority,
          null,
          [],
          tags,
        );

    setTimeout(() => {
      const fetchData = async () => {
        const groups = await fetchDeviceGroups(tenantName, os);
        const devices = await fetchDevices(tenantName, os);

        setDeviceGroups(groups);
        setDeviceList(devices);
      };

      fetchData();
    }, 1000);
    closeAndCleanDialog();
  };

  const handleSubmitAsOU = async () => {
    const filteredDevices = ouDevices?.filter((device) => device.hasAgent);
    const devicesIds = filteredDevices?.map((device) => device.endpointId);
    const uniqIds = devicesIds?.length ? [...new Set(devicesIds)] : [];

    selectedGroup
      ? editOuGroup(
          selectedGroup.uuid,
          selectedGroup.name,
          deviceGroupName,
          tenantName,
          os,
          selectedProfile.id,
          selectedProfile.name,
          groupPriority,
          selectedOU.id,
          uniqIds,
          tags,
        )
      : createNewDeviceGroupAsOu(
          deviceGroupName,
          tenantName,
          os,
          selectedProfile.id,
          selectedProfile.name,
          groupPriority,
          selectedOU.id,
          uniqIds,
          tags,
        );

    setTimeout(() => {
      const fetchData = async () => {
        const groups = await fetchDeviceGroups(tenantName, os);
        const devices = await fetchDevices(tenantName, os);

        setDeviceGroups(groups);
        setDeviceList(devices);
      };

      fetchData();
    }, 1000);
    closeAndCleanDialog();
  };

  const getAllMatchedResources = (newPatterns) => {
    return deviceList.filter((device) => {
      return newPatterns.some((pattern) => {
        const regex = createDeviceGroupPatternRegex(pattern.text);
        return device.dNSHostName.match(regex);
      });
    });
  };

  const handlePriority = (event) => {
    const newValue = event.target.value;

    if (newValue === "" || (newValue >= 1 && newValue <= 100)) {
      setGroupPriority(newValue);
    }
  };

  const handleAddPattern = () => {
    const id = uuidv4();
    addPatternToGroup(patternInputText, id);
    setActiveChip({ id, text: patternInputText });
  };

  const handleClickChip = (pattern) => {
    setPatternInputText("");
    checkMatches(pattern.text);
    setActiveChip(pattern);
  };

  const handleAllMatchesChip = () => {
    setPatternInputText("");
    setActiveChip({ id: 999, text: "ALL" });
    setMatchedDevices(getAllMatchedResources(searchPatternList));
  };

  const isSubmitButtonEnabled = () => {
    if (
      deviceGroupName &&
      (tags.length ||
        searchPatternList.length ||
        (selectedOU && ouDevices?.some((device) => device.hasAgent))) &&
      !(deviceGroupName.trim() === "") &&
      groupPriority
    )
      return true;
    else return false;
  };

  const handlePatternInputChange = (e) => {
    setPatternInputText(e.target.value);
    setActiveChip(null);
  };

  const cancelDialog = () => {
    setManualExclusions([]);
    closeAndCleanDialog();
  };

  const handleEditBtn = (group) => {
    setDeviceGroupName(group.name);
    setGroupPriority(group.reputationScore);

    const relevantExclusions = group.manualExclusions?.filter((exclusion) =>
      deviceList.some((device) => exclusion.id === device.id),
    );
    setManualExclusions(relevantExclusions);

    const oldPatterns = selectedGroup.searchPatterns.map((pattern) => ({
      id: uuidv4(),
      text: pattern,
    }));
    setSearchPatternList(oldPatterns);

    const profile = profilesList?.find(
      (profile) => profile.name === selectedGroup.profileName,
    );
    setSelectedProfile(profile);

    const ou = {
      name: selectedGroup.organizationalUnitName,
      id: selectedGroup.organizationalUnitId,
    };
    setSelectedOU(ou);
    const tags = selectedGroup?.installTags;
    if (tags?.length > 0) {
      setRadioValue("TAG");
      setTags(tags);
    } else {
      const radio = selectedGroup.organizationalUnitName ? "OU" : "PATTERNS";
      setRadioValue(radio);
    }
    setIsDialogOpen(true);
  };

  const submitForGroup = (radioValue) => {
    switch (radioValue) {
      case "OU":
        return handleSubmitAsOU();
      case "PATTERNS":
        return handleSubmit();
      default:
        return handleSubmit();
    }
  };

  useEffect(() => {
    checkMatches(debouncedSearchTerm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (selectedOU?.name && !selectedGroup) setDeviceGroupName(selectedOU.name);
  }, [selectedGroup, selectedOU]);

  return (
    <>
      {selectedGroup ? (
        <Button
          size="medium"
          variant="outlined"
          color="primary"
          onClick={() => handleEditBtn(selectedGroup)}
        >
          Edit
        </Button>
      ) : (
        <Button
          size="medium"
          variant="outlined"
          color="primary"
          onClick={() => {
            setTags([]);
            setIsDialogOpen(true);
          }}
        >
          Create new group
        </Button>
      )}
      <Dialog open={isDialogOpen} onClose={cancelDialog} fullWidth>
        <DialogContent sx={{ overflow: "hidden" }}>
          <Stack spacing={4}>
            <InputForm
              groupPriority={groupPriority}
              deviceGroupName={deviceGroupName}
              setDeviceGroupName={setDeviceGroupName}
              handlePriority={handlePriority}
            />
            <ProfileSelection
              selectedOS={os}
              profileList={profilesList}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
            />

            <RadioSelection
              radioValue={radioValue}
              setRadioValue={setRadioValue}
              selectedGroup={selectedGroup}
              os={os}
            />

            {radioValue === "PATTERNS" ? (
              <>
                <AddPatternForm
                  searchPatternList={searchPatternList}
                  activeChip={activeChip}
                  handleDeletePattern={handleDeletePattern}
                  handleClickChip={handleClickChip}
                  handleAllMatchesChip={handleAllMatchesChip}
                  patternInputText={patternInputText}
                  handlePatternInputChange={handlePatternInputChange}
                  matchedDevices={matchedDevices}
                  handleAddPattern={handleAddPattern}
                />

                {matchedDevices.length ? (
                  <Box
                    component={"fieldset"}
                    borderRadius={1}
                    overflow={"auto"}
                    maxHeight={"230px"}
                  >
                    <legend>Matched Devices</legend>
                    <MatchedResourceTable
                      setManualExclusions={setManualExclusions}
                      manualExclusions={manualExclusions}
                      matchedResources={matchedDevices}
                      resourceNameField={"dNSHostName"}
                    />
                  </Box>
                ) : null}
              </>
            ) : radioValue === "OU" ? (
              <>
                <OuSelection
                  setSelectedOU={setSelectedOU}
                  selectedOU={selectedOU}
                  tenantName={tenantName}
                />
                {selectedOU ? (
                  <Box
                    component={"fieldset"}
                    borderRadius={1}
                    overflow={"auto"}
                    maxHeight={"230px"}
                  >
                    <legend>Organization Unit Devices</legend>
                    <MatchedDevicesTable
                      setOuDevices={setOuDevices}
                      ouDevices={ouDevices}
                      unitId={selectedOU?.id}
                    />
                  </Box>
                ) : null}
              </>
            ) : (
              <>
                <Box>
                  <Box display="flex" alignItems="center">
                    <TextField
                      size="small"
                      label="Add Tag"
                      value={tag}
                      onChange={handleTagChange}
                      onKeyPress={handleKeyPress}
                      variant="outlined"
                      fullWidth
                    />
                    <Button
                      disabled={tag.length === 0}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={handleAddTag}
                      sx={{ marginLeft: 1 }}
                    >
                      Add
                    </Button>
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    mt={2}
                    flexWrap="wrap"
                    gap={1}
                  >
                    {tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDeleteTag(tag)}
                        color="primary"
                      />
                    ))}
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeAndCleanDialog}>
            Cancel
          </Button>
          <Button
            disabled={!isSubmitButtonEnabled()}
            variant="contained"
            onClick={() => {
              submitForGroup(radioValue);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const InputForm = ({
  groupPriority,
  deviceGroupName,
  setDeviceGroupName,
  handlePriority,
}) => {
  return (
    <Box
      height={60}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <TextField
        required
        autoFocus
        id="name"
        label="Group Name"
        type="text"
        fullWidth
        variant="outlined"
        value={deviceGroupName}
        onChange={(e) => setDeviceGroupName(e.target.value)}
        inputProps={{ maxLength: 250 }}
        sx={{ height: 45, width: "70%" }}
      />
      <Tooltip
        disableFocusListener
        title="If a device evaluates to multiple groups, it will be assigned the group with highest priority and will take the profile of that group."
        // enterDelay={500}
        leaveDelay={200}
      >
        <TextField
          required
          autoFocus
          id="name"
          label="Priority"
          type="text"
          variant="outlined"
          value={groupPriority}
          onChange={handlePriority}
          sx={{
            height: 45,
            width: "25%",
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default DeviceGroupDialog;
