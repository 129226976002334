import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import axios from "axios";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import UserSearch from "../RegisteredUsers/components/UserSearch";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import {
  CheckCircle as RegisteredIcon,
  Cancel as UnregisteredIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
const ActiveDirectoryUsers = () => {
  const userData = useUserStore((state) => state.user);

  const [tenantList, setTenantList] = useState([]);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [adusers, setAdUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const [isSuccessInvite, setIsSuccessInvite] = useState(false);
  const [isErrorInvite, setIsErrorInvite] = useState(false);
  const [successData, setSuccessData] = useState("");
  const [errorData, setErrorData] = useState("");
  const [toggledRow, setToggledRow] = useState(null);

  const {
    searchTerm,
    filteredData: usersList,
    handleSearch,
  } = useGeneralSearch(adusers);

  const isEveryUserSelected =
    selectedUsers.length === usersList.length ? true : false;
  const selectAllUsers = () => {
    const allUsersAlreadySelected = selectedUsers.length === usersList.length;

    if (allUsersAlreadySelected) setSelectedUsers([]);
    else setSelectedUsers(usersList);
  };
  const checkIfUserSelected = (user) =>
    selectedUsers.some(
      (selectedUser) => JSON.stringify(user) === JSON.stringify(selectedUser),
    );
  const handleUserSelect = (user) => {
    const userAlreadySelected = selectedUsers.some(
      (selectedUser) => JSON.stringify(selectedUser) === JSON.stringify(user),
    );

    if (userAlreadySelected) {
      const updatedUsers = selectedUsers.filter(
        (selectedUser) => JSON.stringify(selectedUser) !== JSON.stringify(user),
      );
      setSelectedUsers(updatedUsers);
    } else {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };
  const handleToggleCode = (id) => {
    setToggledRow((prev) => (prev === id ? null : id));
  };
  const fetchOrganizationalUnits = async () => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenantName}/organization-units`,
    );
    return response.data;
  };

  const fetchUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenantName}/users`,
    );
    return response.data;
  };

  const getAllRegisteredUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/keycloak/${selectedTenantName}/users`,
    );
    return response.data;
  };
  const getUserInviteList = async () => {
    const response = await axios.get(
      `${NGROK}/api/invite/pending?tenantName=${selectedTenantName}`,
    );
    return response.data;
  };

  const combineData = (units, users, registeredUsers, invitedUsers) => {
    return users.map((user) => {
      const ou = units.find((unit) =>
        user.DistinguishedName.includes(unit.distinguishedName),
      );
      const regUser = user.EmailAddress
        ? registeredUsers.find((reg) => user.EmailAddress.includes(reg.email))
        : null;
      const isInvited = user.EmailAddress
        ? invitedUsers.find((reg) => user.EmailAddress.includes(reg.email))
        : null;
      return {
        DistinguishedName: user.DistinguishedName,
        Name: user.Name,
        SamAccountName: user.SamAccountName,
        MobilePhone: user.MobilePhone,
        UserPrincipalName: user.UserPrincipalName,
        EmailAddress: user.EmailAddress,
        OU: ou ? ou.name : null,
        isReg: regUser ? true : false,
        isInvited: isInvited ? true : false,
        code: isInvited ? isInvited.verificationCode : null,
      };
    });
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);
  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [units, users, registeredUsers, invitedUsers] = await Promise.all(
          [
            fetchOrganizationalUnits(),
            fetchUsers(),
            getAllRegisteredUsers(),
            getUserInviteList(),
          ],
        );
        const combinedData = combineData(
          units,
          users,
          registeredUsers,
          invitedUsers,
        );
        setAdUsers(combinedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, [selectedTenantName]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterSelect = (filterOption) => {
    setFilter(filterOption);
    handleFilterClose();
  };

  const filteredUsersList = usersList.filter((user) => {
    if (filter === "all") return true;
    if (filter === "registered") return user.isReg;
    if (filter === "unregistered") return !user.isReg;
    return true;
  });
  const hasUnregisteredUsers = selectedUsers.some((user) => !user.isReg);

  const emailInviteUser = async (user) => {
    const fullName = user.Name;
    const spaceIndex = fullName.indexOf(" ");
    const firstName = fullName.substring(0, spaceIndex);
    const lastName = fullName.substring(spaceIndex + 1);
    let values = {
      firstName: firstName,
      lastName: lastName,
      email: user.EmailAddress,
      tenantName: selectedTenantName,
    };
    await axios
      .post(`${NGROK}/api/invite/send`, {
        ...values,
      })
      .then((resp) => {
        let { isOk } = resp.data;
        if (isOk) {
          setIsSuccessInvite(true);
          setIsErrorInvite(false);
          setSuccessData("Invite Sent Successfully");
        } else {
          setIsSuccessInvite(false);
          setIsErrorInvite(true);
          setErrorData("Invite already sent to this mail");
        }
      })
      .catch((err) => {
        setIsSuccessInvite(false);
        setIsErrorInvite(true);
        setErrorData("Invite already sent to this mail");
      });
  };

  const sendBulkInviteLink = () => {
    const invitePromises = selectedUsers
      .filter((userreg) => !userreg.isReg)
      .filter((invites) => !invites.isInvited)
      .filter((emailFilter) => emailFilter.EmailAddress !== null)
      .map((user) => {
        const fullName = user.Name;
        const spaceIndex = fullName.indexOf(" ");
        const firstName = fullName.substring(0, spaceIndex);
        const lastName = fullName.substring(spaceIndex + 1);

        let values = {
          firstName: firstName,
          lastName: lastName,
          email: user.EmailAddress,
          tenantName: selectedTenantName,
        };

        return axios.post(`${NGROK}/api/invite/send`, {
          ...values,
        });
      });

    Promise.all(invitePromises)
      .then((responses) => {
        const allSuccessful = responses.every((response) => response.data.isOk);
        if (allSuccessful) {
          setIsSuccessInvite(true);
          setIsErrorInvite(false);
          setSuccessData("Email invites sent successfully");
        } else {
          setIsSuccessInvite(false);
          setIsErrorInvite(true);
          setErrorData("Some invites could not be sent");
        }
      })
      .catch((error) => {
        setIsSuccessInvite(false);
        setIsErrorInvite(true);
        setErrorData("Error sending email invite");
      });
  };
  return (
    <>
      <Stack sx={{ paddingBottom: 5 }} direction={"row"} spacing={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {tenantList?.length > 0 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedTenantName ? selectedTenantName : " "}
              options={tenantList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => {
                handleTenantChange(value);
              }}
            />
          ) : null}
        </Box>
        <UserSearch searchTerm={searchTerm} handleSearch={handleSearch} />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ mr: "15px !important" }}>
          <Stack spacing={2} direction={"row"}>
            <Button
              variant="outlined"
              disabled={!hasUnregisteredUsers}
              startIcon={<InsertInvitationIcon />}
              onClick={sendBulkInviteLink}
            >
              Invite Now
            </Button>
            <Button
              variant="outlined"
              disabled={usersList.length === 0}
              startIcon={<FilterListIcon />}
              onClick={handleFilterClick}
            >
              Filter
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleFilterClose}>
              <MenuItem onClick={() => handleFilterSelect("all")}>
                Show All
              </MenuItem>
              <MenuItem onClick={() => handleFilterSelect("registered")}>
                Registered Users
              </MenuItem>
              <MenuItem onClick={() => handleFilterSelect("unregistered")}>
                Unregistered Users
              </MenuItem>
            </Menu>
          </Stack>
        </Box>
      </Stack>
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                <Checkbox
                  checked={isEveryUserSelected}
                  onChange={selectAllUsers}
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                Organisation Unit
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                DistinguishedName
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                SamAccountName
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                UserPrincipalName
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                EmailAddress
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                Verification Code
              </TableCell>

              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "14px" }}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!loading ? (
            <TableBody>
              {filteredUsersList.length > 0 ? (
                filteredUsersList?.map((user, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      border: 1,
                    }}
                  >
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Checkbox
                        size="small"
                        checked={checkIfUserSelected(user)}
                        onChange={() => handleUserSelect(user)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {user.Name ? user.Name : "-"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {user.OU ? user.OU : "-"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {user.DistinguishedName ? user.DistinguishedName : "-"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {user.SamAccountName ? user.SamAccountName : "-"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {user.UserPrincipalName ? user.UserPrincipalName : "-"}
                    </TableCell>

                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {user.EmailAddress ? user.EmailAddress : "-"}
                    </TableCell>
                    <TableCell>
                      {user.isInvited ? (
                        <Button
                          onClick={() => handleToggleCode(user.EmailAddress)}
                        >
                          {toggledRow === user.EmailAddress ? (
                            <>
                              {user.code} <LockOpenIcon />
                            </>
                          ) : (
                            <LockIcon />
                          )}
                        </Button>
                      ) : (
                        <Button disabled>
                          <LockIcon />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {user.isReg ? (
                        <Button
                          variant="outlined"
                          disabled
                          color="success"
                          sx={{
                            color: "#398b66 !important",
                            border: "1px solid #398b66 !important",
                          }}
                        >
                          Registered
                        </Button>
                      ) : user.isInvited ? (
                        <Button
                          variant="outlined"
                          disabled
                          sx={{
                            color: "#2a75e8 !important",
                            border: "1px solid #2a75e8 !important",
                          }}
                        >
                          Invited
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled={user.EmailAddress ? false : true}
                          onClick={() => emailInviteUser(user)}
                        >
                          Invite
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell colSpan={9} align="center">
                  No Active Directory users available
                </TableCell>
              )}
            </TableBody>
          ) : (
            <Box display={"flex"} p={5}>
              <CircularProgress />
            </Box>
          )}
        </Table>
      </TableContainer>
      {isSuccessInvite && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setIsSuccessInvite(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsSuccessInvite(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {successData}
          </Alert>
        </Snackbar>
      )}
      {isErrorInvite && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setIsErrorInvite(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setIsErrorInvite(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorData}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ActiveDirectoryUsers;
