export const formatDateTime = (dateTimeStr) => {
  const isUTC = dateTimeStr.endsWith("Z");

  if (!isUTC) {
    dateTimeStr = dateTimeStr + "Z";
  }
  const dateTime = new Date(dateTimeStr);
  const now = new Date();
  const utcNow = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    )
  );

  const diffMs = utcNow - dateTime;
  const diffMins = Math.round(diffMs / (1000 * 60));

  if (diffMins < 1) {
    return "just now";
  } else if (diffMins === 1) {
    return "1 min ago";
  } else if (diffMins < 60) {
    return `${diffMins} mins ago`;
  } else {
    const diffHours = Math.floor(diffMins / 60);
    if (diffHours < 24) {
      return `${diffHours} hrs ago`;
    } else {
      const diffDays = Math.floor(diffHours / 24);
      if (diffDays < 7) {
        return `${diffDays} days ago`;
      } else {
        const diffWeeks = Math.floor(diffDays / 7);
        if (diffWeeks < 4) {
          return `${diffWeeks} weeks ago`;
        } else {
          const diffMonths = Math.floor(diffDays / 30);
          if (diffMonths < 12) {
            if (diffMonths === 0) return `A month ago`;
            else return `${diffMonths} months ago`;
          } else {
            const diffYears = Math.floor(diffDays / 365);
            if (diffYears === 0) return `A year ago`;
            else return `${diffYears} years ago`;
          }
        }
      }
    }
  }
};
