import { useContext } from "react";

import { AuthContext } from "../contexts/JWTContext";
// import { AuthContext } from "../contexts/PasskeyContext";

const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};

export default useAuth;
