import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SearchField from "./components/SearchField";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import CustomDatePicker from "./components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";

const ServerDebugLogs = () => {
  const [applicationLogs, setApplicationLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const forbiddenSearchFields = ["bucket", "tenantName"];
  const {
    searchTerm,
    filteredData: logsFilteredBySearch,
    handleSearch,
  } = useGeneralSearch(applicationLogs, null, forbiddenSearchFields);

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(logsFilteredBySearch, "time");

  useEffect(() => {
    const controller = new AbortController();

    const fetchApplicationLogs = async () => {
      try {
        const res = await axios.get(`${NGROK}/api/application-logs`, {
          signal: controller.signal,
        });
        setApplicationLogs(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error, "error fetchApplicationLogs ");
        setLoading(false);
      }
    };

    fetchApplicationLogs();

    const interval = setInterval(() => {
      fetchApplicationLogs();
    }, 5000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <Stack spacing={2}>
      <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
      <CustomDatePicker
        dateRange={dateRange}
        handleChangeDateRange={handleChangeDateRange}
      />
      {logs.length ? (
        <TableContainer
          component={Paper}
          sx={{
            overflow: "hidden",
            display: "flex",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
              width: "fit-content",
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Time
                </TableCell>

                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Message
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.time}
                  </TableCell>

                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.message}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.loggedInUserEmail || log.computerName || "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography fontSize={16} p={5}>
          Logs are empty
        </Typography>
      )}
    </Stack>
  );
};

export default ServerDebugLogs;
