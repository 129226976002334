import React from "react";
import SignUpForm from "./components/SignUpForm";
import { Container, CssBaseline, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthenticationPage from "./components/AuthenticationPage";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#233141",
    },
  },
});

const CustomSignUp = () => {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <AuthenticationPage theme={defaultTheme}>
          <Container maxWidth="xs">
            <CssBaseline />
            <Paper
              elevation={5}
              sx={{
                maxHeight: 450,
                minHeight: 450,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 3,
                borderRadius: 5,
              }}
            >
              <SignUpForm />
            </Paper>
          </Container>
        </AuthenticationPage>
      </ThemeProvider>
    </>
  );
};

export default CustomSignUp;
