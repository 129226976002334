import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const OTPAuthentication = ({
  verifyEmailInvite,
  hasOTPError,
  setHasOTPError,
  onOTPChange,
  otp,
}) => {
  const inputs = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const otp = Array.from(inputs.current)
        .map((input) => input.value)
        .join("");
      setHasOTPError(false);
      onOTPChange(otp);
      if (value.length === 1 && index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !inputs.current[index].value && index > 0) {
      inputs.current[index - 1].focus();
      setHasOTPError(false);
    }
  };
  useEffect(() => {
    if (otp.length === 6 && !hasOTPError) {
      verifyEmailInvite(otp);
    }
  }, [otp, verifyEmailInvite, hasOTPError]);
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ marginTop: "-35px !important" }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
          Enter Verification Code
        </Typography>
        <Box display="flex" justifyContent="center" gap={2}>
          {Array.from({ length: 6 }).map((_, index) => (
            <TextField
              key={index}
              inputRef={(el) => (inputs.current[index] = el)}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: hasOTPError ? "red" : "default",
                  },
                  "&:hover fieldset": {
                    borderColor: hasOTPError ? "red" : "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: hasOTPError ? "red" : "default",
                  },
                },
              }}
            />
          ))}
        </Box>
        {hasOTPError && (
          <Typography variant="body1" sx={{ mt: 2, color: "red" }}>
            Invalid OTP
          </Typography>
        )}
      </Box>
    </>
  );
};

export default OTPAuthentication;
