import React, { useState, useEffect } from "react";
import axios from "axios";
import Papa from "papaparse";
import styled, { withTheme } from "styled-components";
import { Chart } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";
import { NGROK } from "../../../APIs";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function LineChart({
  theme,
  selectedTenantName,
  dateRange = [0, 11],
  stepsCompleted,
}) {
  const [privilegedUsersNumbers, setPrivilegedUsersNumbers] = useState([]);

  useEffect(() => {
    const getPrivilegedUsersCount = async () => {
      const res = await axios.get(
        `${NGROK}/api/statistics/groups/count?tenantName=${selectedTenantName}`,
      );
      const privilegeNumbers = res.data?.map((el) => el.groupsAmount);
      setPrivilegedUsersNumbers(privilegeNumbers);
    };
    getPrivilegedUsersCount();
  }, [selectedTenantName]);

  const chartLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ].slice(dateRange[0], dateRange[1]);

  const lastDate = dateRange[1] <= 12 ? dateRange[1] : 12;
  const chartData = privilegedUsersNumbers?.slice(dateRange[0], lastDate);

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
    gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

    return {
      labels: chartLabels,
      datasets: [
        {
          label: "Sales ($)",
          fill: true,
          backgroundColor: gradient,
          borderColor: theme.palette.secondary.main,
          tension: 0.3,
          data: chartData,
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
      },
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
      },
    },
  };

  const downloadCSV = (data, filename) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);
  };

  const handleDownloadCSV = () => {
    downloadCSV([chartLabels, chartData], "data.csv");
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Privilege creep over time"
      />
      <CardContent>
        <ChartWrapper>
          <Chart type="line" data={data} options={options} />
        </ChartWrapper>
        {stepsCompleted ? (
          <Button
            sx={{ margin: 5 }}
            variant="outlined"
            onClick={handleDownloadCSV}
          >
            Export CSV
          </Button>
        ) : null}
      </CardContent>
    </Card>
  );
}
export default withTheme(LineChart);
