import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
  Stack,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";
import CustomSnackbar from "./CustomSnackbar";
import { myLocalStorage } from "../../components/StorageHelper";
import UserService from "../../services/UserService";
import { decodeKeycloakToken } from "../../utils/tokenHelpers";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function Account() {
  const userData = useUserStore((state) => state.user);
  const storedToken = myLocalStorage.getItem("access_token");
  const token = UserService?.getToken() || storedToken;
  const kcUserData = decodeKeycloakToken(token);

  const [accountInfo, setAccountInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const {
    id,
    firstName = "",
    lastName = "",
    city = "",
    apartment = "",
    state = "",
    zip = null,
    username = "",
    biography = "",
  } = accountInfo;

  const privateUpdateData = async () => {
    try {
      const response = await axios.put(
        `${NGROK}/api/account/${id}/settings/private-info`,
        {
          accountId: id,
          apartment,
          city,
          firstName,
          lastName,
          state,
          zip,
        },
      );
      return response;
    } catch (error) {
      console.error("Private API request failed:", error);
      throw error;
    }
  };

  const publicUpdateData = async () => {
    try {
      const isUserNameUnique = await axios.get(
        `${NGROK}/api/account/check-username?username=${username}`,
      );
      if (isUserNameUnique) {
        const response = await axios.put(
          `${NGROK}/api/account/${id}/settings/public-info`,
          {
            accountId: id,
            biography,
            username,
          },
        );
        return response;
      }
    } catch (error) {
      console.log("user is not unique");
      throw error;
    }
  };

  const handleUpdateData = async () => {
    try {
      const isPublicUpdateData = await publicUpdateData();
      const isPrivateUpdateData = await privateUpdateData();
      if (isPublicUpdateData && isPrivateUpdateData) {
        setOpen(true);
        setSnackbarMessage("Saved");
        setSnackbarSeverity("success");
      }
    } catch (error) {
      setOpen(true);
      console.error("An error occurred:", error);
      setSnackbarMessage("Error: Something went wrong");
      setSnackbarSeverity("error");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${NGROK}/api/account`);
        setAccountInfo(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userData]);

  return (
    <>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Account
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public
            kcUserData={kcUserData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <Private
            kcUserData={kcUserData}
            userData={userData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <CardContent>
            <Button
              onClick={handleUpdateData}
              variant="contained"
              color="primary"
            >
              Save changes
            </Button>
            <CustomSnackbar
              open={open}
              snackbarMessage={snackbarMessage}
              snackbarSeverity={snackbarSeverity}
              onClose={handleClose}
            />
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
}

function Public({ accountInfo, setAccountInfo, kcUserData }) {
  const maxLength = 200;
  const biography = accountInfo.biography || "";
  const currentLength = biography.length;

  return (
    <Card mb={6} sx={{ width: "fit-content" }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Public info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={8}>
            <Stack direction={"row"} spacing={2}>
              <Typography fontWeight={600} variant="subtitle1">
                Username:
              </Typography>
              <Typography variant="subtitle1">{kcUserData.email}</Typography>
            </Stack>

            <FormControl
              fullWidth
              my={2}
              variant="outlined"
              sx={{ minWidth: 600, maxWidth: 800 }}
            >
              <TextField
                value={biography}
                onChange={(e) => {
                  const newValue = e.target.value;

                  if (newValue.length <= maxLength) {
                    setAccountInfo({ ...accountInfo, biography: newValue });
                  }
                }}
                label="Biography"
                id="biography"
                multiline
                rows={3}
                variant="outlined"
                inputProps={{ maxLength }}
              />
              <FormHelperText>{`${currentLength}/${maxLength} characters`}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Private({ accountInfo, setAccountInfo, kcUserData }) {
  const { city, apartment, state, zip } = accountInfo;

  const handleChange = (e) => {
    setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
  };
  const handleZipChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
    }
  };
  const handleZipKeyDown = (e) => {
    const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
    if (!/^\d$/.test(e.key) && !validKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Private info
        </Typography>

        <Stack spacing={4} direction={"row"}>
          <Stack direction={"row"} spacing={2}>
            <Typography fontWeight={600} variant="subtitle1">
              First name:
            </Typography>
            <Typography variant="subtitle1">{kcUserData.given_name}</Typography>
          </Stack>

          <Stack direction={"row"} spacing={2}>
            <Typography fontWeight={600} variant="subtitle1">
              Last name:
            </Typography>
            <Typography variant="subtitle1">
              {kcUserData.family_name}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={"row"} spacing={2}>
          <Typography fontWeight={600} variant="subtitle1">
            Email:
          </Typography>
          <Typography variant="subtitle1">{kcUserData.email}</Typography>
        </Stack>
        {/* 
        <TextField
          value={setp.username}
              onChange={(e) => setPublicInfo({...publicInfo, username: e.target.value})}
          id="address"
          label="Address"
          variant="outlined"
          fullWidth
          my={2}
        /> */}

        <TextField
          name={"apartment"}
          value={apartment || ""}
          onChange={(e) => handleChange(e)}
          id="address2"
          label="Apartment"
          variant="outlined"
          fullWidth
          my={2}
        />

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              name={"city"}
              value={city || ""}
              onChange={(e) => handleChange(e)}
              id="city"
              label="City"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              name={"state"}
              value={state || ""}
              onChange={(e) => handleChange(e)}
              id="state"
              label="State"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              name={"zip"}
              value={zip || ""}
              onChange={(e) => handleZipChange(e)}
              onKeyDown={(e) => handleZipKeyDown(e)}
              id="zip"
              label="ZIP code"
              variant="outlined"
              fullWidth
              my={2}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Account;
