import { useState, useEffect } from "react";
import moment from "moment";

const useDateFilter = (initialData, timeField) => {
  const [filteredData, setFilteredData] = useState(initialData);
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChangeDateRange = (newValue) => {
    const parsedDates = newValue.map((el) => (el ? el.getTime() : null));
    setDateRange(parsedDates);
  };

  useEffect(() => {
    const filterLogsByDateRange = () => {
      const filtered = initialData.filter((log) => {
        const logDate = moment(log[timeField]).format("MM/DD/YYYY");
        const startDate =
          dateRange[0] && moment(dateRange[0]).format("MM/DD/YYYY");
        const endDate =
          dateRange[1] && moment(dateRange[1]).format("MM/DD/YYYY");

        const isAfterStartDate =
          !startDate || moment(logDate).isSameOrAfter(startDate, "day");
        const isBeforeEndDate =
          !endDate || moment(logDate).isSameOrBefore(endDate, "day");

        return isAfterStartDate && isBeforeEndDate;
      });

      setFilteredData(filtered);
    };

    filterLogsByDateRange();
  }, [dateRange, initialData, timeField]);

  return {
    dateRange,
    handleChangeDateRange,
    filteredData,
  };
};

export default useDateFilter;
