import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import moment from "moment";
import { awsIcons } from "../AWSIcons";

const S3Buckets = ({ s3Datas, setS3Datas, tenantName }) => {
  const [loading, setLoading] = useState(false);
  const fetchS3Buckets = async (isFirst = false) => {
    if (isFirst) {
      setLoading(true);
    }
    if (tenantName) {
      const response = await axios.get(
        `${NGROK}/api/aws/getAllS3Buckets?tenantName=${tenantName}`,
      );
      setS3Datas(response?.data);
    }
    setLoading(false);
  };

  const updateS3Manage = async (bucketName) => {
    if (tenantName) {
      const response = await axios.put(
        `${NGROK}/api/aws/publishBucket?tenantName=${tenantName}&bucketName=${bucketName}`,
      );
      fetchS3Buckets();
    }
  };

  useEffect(() => {
    fetchS3Buckets(true);
    const interval = setInterval(fetchS3Buckets, 5000);
    return () => clearInterval(interval);
  }, [tenantName]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          height: "fit-content",
          "& th": {
            background: "#233044",
            color: "#fff",
          },
          "& td, & th": {
            border: "1px solid #233044",
            fontSize: "18px",
          },
        }}
        size="large"
      >
        <TableHead>
          <TableRow>
            <TableCell>Bucket Name</TableCell>
            <TableCell>Creation Date</TableCell>
            <TableCell>Management</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {s3Datas.map((bucket, index) => (
            <TableRow key={index}>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {awsIcons.buckets}
                  <Box ml={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 18,
                      }}
                    >
                      {" "}
                      {bucket.bucketName}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                {moment(bucket.creationDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Switch
                    checked={bucket.isManagedS3}
                    onChange={() => {
                      updateS3Manage(bucket.bucketName);
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 16,
                    }}
                  >
                    Publish
                  </Typography>{" "}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default S3Buckets;
