import React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CodeBlockInfo = () => {
  return (
    <section className="firstStep">
      <p className="heading">
        Powershell <ContentCopyIcon />
      </p>
      <div className="codeBlock">
        <p>
          <span className="blueText">
            New-ADServiceAccount -Name "Service01" -DNSHostName
            "Service01.contoso.com" -Enabled $True
          </span>
        </p>
      </div>
    </section>
  );
};

export default CodeBlockInfo;
