import React, { useState, useEffect } from "react";

import {
  Avatar,
  Box,
  Breadcrumbs,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import useUserStore from "../services/userStore";
import { myLocalStorage } from "./StorageHelper";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";
import { formatDateTime } from "../GenericMethods";
import RouterIcon from "@mui/icons-material/Router";
import { RiComputerLine } from "react-icons/ri";

const BreadcrumbsNavEndpoints = () => {
  const location = useLocation();

  const lastSession = useUserStore((state) => state.lastSession);
  const activeComputer = useUserStore((state) => state.activeComputer);
  const latestComputer =
    activeComputer || myLocalStorage.getItem("activeComputer");
  const latestTenant =
    lastSession.latestTenant ||
    myLocalStorage.getItem("latestTenant")?.tenantName;

  const [computerName, setComputerName] = useState("");
  const [onlineStatus, setOnlineStatus] = useState("");
  const [agentVersion, setAgentVersion] = useState("");
  const [operatingSystem, setOperatingSystem] = useState("");
  const { role, userId } = useUserStore((state) => state.user);

  const operatingSystems = {
    linux: "LINUX",
    windows: "WINDOWS",
    mac: "MAC",
  };

  const os = Object.keys(operatingSystems).find((key) =>
    latestComputer?.OperatingSystem?.toLowerCase().includes(key),
  );

  const osIcon = {
    linux: <FaLinux size={40} color={"#233044"} />,
    windows: <FaWindows size={35} color={"#233044"} />,
    mac: <FaApple size={40} color={"#233044"} />,
  };

  const getStatusIndicator = (status) => (
    <Box
      sx={{
        display: "inline-block",
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: getStatusColor(status),
        marginRight: 1,
      }}
    />
  );

  const getLastSeenStatus = (dateTimeStr) => {
    if (dateTimeStr) {
      const isUTC = dateTimeStr.endsWith("Z");

      if (!isUTC) {
        dateTimeStr = dateTimeStr + "Z";
      }
      const dateTime = new Date(dateTimeStr);
      const now = new Date();
      const utcNow = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds(),
        ),
      );

      const diffMs = utcNow - dateTime;
      if (diffMs <= 5 * 60 * 1000) {
        return "online";
      } else {
        return formatDateTime(dateTimeStr);
      }
    } else {
      return "offline";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "online":
        return "green";
      case "-":
        return "grey";
      default:
        return "red";
    }
  };

  useEffect(() => {
    if (latestComputer) {
      setComputerName(
        latestComputer.cn
          ? latestComputer.cn
          : latestComputer.dNSHostName
            ? latestComputer.dNSHostName
            : latestComputer.domainName,
      );
      const status = getLastSeenStatus(latestComputer?.lastSeen);
      setOnlineStatus(status);
      if (latestComputer?.isManuallyAddedMachine) {
        setOnlineStatus("online");
      }
      setAgentVersion(latestComputer?.agentVersion);
      setOperatingSystem(latestComputer?.OperatingSystem);
    }
  }, [latestComputer]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ fontSize: "22px", mt: -5 }}
    >
      <Link style={{ color: "black" }} to={"/endpoints"}>
        Tenants
      </Link>
      {latestTenant ? (
        <Typography fontSize={22} color="text.primary">
          {latestTenant}
        </Typography>
      ) : null}
      <Link style={{ color: "black" }} to={"/endpoints"}>
        Computers
      </Link>
      {location.pathname !== "/endpoints" && latestComputer ? (
        <Typography fontSize={22} color="text.primary">
          <Stack spacing={5} direction={"row"} alignItems={"center"}>
            <Avatar sx={{ backgroundColor: "white" }}>
              {latestComputer?.isManuallyAddedMachine ? (
                <RiComputerLine size={40} color={"#233044"} />
              ) : (
                osIcon[os]
              )}
            </Avatar>

            <Stack>
              <Stack direction={"row"} spacing={2}>
                <span>{computerName && computerName}</span>
                {role !== "TENANT_USER" && latestComputer?.isGateway && (
                  <Box>
                    <Tooltip title="Gateway">
                      <IconButton>
                        <RouterIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Stack>

              <Typography
                component="span"
                sx={{ fontSize: "14px", display: "inline" }}
                color="text.secondary"
              >
                {"OS: "}
                {operatingSystem}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: "14px", display: "inline" }}
                color="text.secondary"
              >
                {onlineStatus !== "-" && getStatusIndicator(onlineStatus)}

                {onlineStatus === "online" ? (
                  latestComputer?.isManuallyAddedMachine ? (
                    <span>{"Configured"}</span>
                  ) : (
                    <span>{"Online"}</span>
                  )
                ) : onlineStatus === "-" ? (
                  <>{"Status: -"}</>
                ) : (
                  <Tooltip title={`${onlineStatus}`}>
                    <span>{"Offline"}</span>
                  </Tooltip>
                )}
              </Typography>
            </Stack>
          </Stack>
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/resources" ? (
        <Typography fontSize={22} color="text.primary">
          Resources
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/sessions" ? (
        <Typography fontSize={22} color="text.primary">
          Sessions
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/connections" ? (
        <Typography fontSize={22} color="text.primary">
          Connections
        </Typography>
      ) : null}
      {location.pathname === "/endpoints/deviceAuditLogs" ? (
        <Typography fontSize={22} color="text.primary">
          Audit Logs
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavEndpoints;
