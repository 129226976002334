import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import HistoryIcon from "@mui/icons-material/History";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { BsFiletypeExe } from "react-icons/bs";
import { formatDateTime } from "../../../GenericMethods";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 885px;
`;
const useStyles = makeStyles({
  root: {
    height: "855px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const TimeLineSeries = ({ data }) => {
  const classes = useStyles();
  const [recentActivityData, setrecentActivityData] = useState(null);

  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getActivity(10);
  };
  const getActivity = (limit) => {
    if (data) {
      data.sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
      });
      const startIndex = (page - 1) * limit;
      const endIndex = startIndex + limit;

      const recentActivity = data.slice(startIndex, endIndex);
      setrecentActivityData(recentActivity);
    }
  };
  useEffect(() => {
    getActivity(10);
  }, [data, page]);

  return (
    <>
      <Card>
        <CardHeader title="Recent LOLBin Activity" />
        <CardContent>
          <ChartWrapper>
            {recentActivityData !== null && (
              <Timeline className={classes.root}>
                {recentActivityData.map((dataVal, index) => (
                  <TimelineItem
                    key={index}
                    sx={{
                      minHeight: 200,
                    }}
                  >
                    <TimelineOppositeContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        minHeight: 150,
                        minWidth: 100,
                      }}
                      variant="body1"
                      color="text.secondary"
                    >
                      {formatDateTime(
                        dataVal.timestamp !== undefined &&
                          dataVal.timestamp !== null
                          ? dataVal.timestamp
                          : dataVal._time,
                      )}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <HistoryIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        minWidth: 560,
                        width: "100%",
                        wordWrap: "break-word",
                      }}
                    >
                      <Box>
                        <Typography variant="body1" fontWeight="bold">
                          {dataVal?.message}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ComputerOutlinedIcon />
                              <div
                                style={{
                                  marginLeft: 5,
                                }}
                              >
                                {dataVal?.computerName?.length > 0
                                  ? dataVal?.computerName
                                  : "localhost"}
                              </div>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <PersonOutlineOutlinedIcon />
                              <div
                                style={{
                                  marginLeft: 5,
                                }}
                              >
                                {dataVal?.computerUserEmail?.length > 0
                                  ? dataVal?.computerUserEmail
                                  : "localhost"}
                              </div>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <BsFiletypeExe
                                style={{
                                  fontSize: "1rem",
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                }}
                              >
                                {dataVal?.resourceName?.length > 0
                                  ? dataVal?.resourceName
                                  : "localhost"}
                              </div>
                            </Box>
                          </Box>
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            )}

            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              sx={{
                mb: 2,
              }}
            >
              {Math.ceil(data.length / 10) >= 2 && (
                <Pagination
                  count={Math.ceil(data.length / 10)}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                />
              )}
            </Box>
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default TimeLineSeries;
