import React, { useState, useEffect } from "react";

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { CgSandClock } from "react-icons/cg";

import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { myLocalStorage } from "../StorageHelper";
import { clearLocalStorageOnLogout } from "../../services/Helpers";
import { getClientId } from "../../services/Auth";
import useUserStore from "../../services/userStore";
import useAuth from "../../hooks/useAuth";
import { useKeycloakStore } from "../../services/UserService";
import { logOut } from "../../services/Auth";

const IdleTimer = () => {
  const navigate = useNavigate();
  // logout data
  const { local_kc } = useKeycloakStore();
  const { signOut } = useAuth();
  const userEmail = useUserStore((state) => state.user.email);
  const email = userEmail || myLocalStorage.getItem("email");
  const logoutUri = myLocalStorage.getItem("logoutUri");
  const access_token = myLocalStorage.getItem("access_token");
  const refresh_token = myLocalStorage.getItem("refresh_token");

  const timeout = 30 * 60 * 1000;
  //   const timeout = 65000;

  const [remaining, setRemaining] = useState(timeout / 1000);

  const [isIdle, setIsIdle] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const handleOnActive = () => {
    setIsIdle(false);
  };
  const handleOnIdle = () => setIsIdle(true);
  const onPrompt = () => {
    setShowLogoutDialog(true);
  };

  const onAction = () => {
    if (showLogoutDialog) {
      setShowLogoutDialog(false);
      handleReset();
    }
  };

  const { activate, getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: true,
    syncTimers: 1000,
    promptBeforeIdle: 60000,
    onPrompt,
    onAction,
    leaderElection: true,
  });

  const handleReset = () => {
    activate();
  };

  const getSeconds = (miliseconds) => {
    return Math.floor((miliseconds / 1000) % 60);
  };

  //logout logic
  const logOutAsInitialAdmin = () => {
    const realm = myLocalStorage.getItem("realm");
    signOut({
      realm,
      client_id: getClientId(realm),
      access_token,
      refresh_token,
    });

    navigate("/");
  };

  const logoutAsUser = () => {
    window.open(logoutUri, "_self");
    myLocalStorage.removeItem("logoutUri");
  };

  const logOutOnClientAndServer = () => {
    const isUserLoginedInitialAdmin = !logoutUri;

    try {
      if (isUserLoginedInitialAdmin) {
        logOutAsInitialAdmin();
      } else {
        logoutAsUser();
      }
    } catch (error) {
      console.error(error);
      local_kc && local_kc.doLogout();
    } finally {
      clearLocalStorageOnLogout();
    }
  };

  useEffect(() => {
    setRemaining(getSeconds(getRemainingTime()));

    const interval = setInterval(() => {
      setRemaining(getSeconds(getRemainingTime()));
    }, 1000);

    return () => clearInterval(interval);
  }, [getRemainingTime]);

  useEffect(() => {
    const handleSignOut = async () => {
      console.log("IDLE timer logout");
      logOut(email)
        .then(() => {
          logOutOnClientAndServer();
        })
        .catch((error) => {
          console.log(error, "error log out");
          logOutOnClientAndServer();
        });
    };

    if (isIdle) {
      handleSignOut();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, isIdle]);

  return (
    <div>
      <React.Fragment>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={showLogoutDialog}
        >
          <DialogTitle
            sx={{ backgroundColor: "#233044", padding: 5 }}
            id="customized-dialog-title"
          >
            <Typography align="center" variant="h3" color={"white"}>
              Session warning
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          ></IconButton>
          <DialogContent dividers>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              padding={4}
            >
              {" "}
              <CgSandClock size={120} color="#233044" />
              <Typography align="center" variant="h4">
                Are you still here?
              </Typography>
              <Typography align="center" variant="subtitle1">
                Your session is going to expire in {remaining} seconds
              </Typography>
            </Box>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default IdleTimer;
