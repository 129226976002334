import React from "react";
import GetStart from "../../GetStart";

const ActiveDirectorySetup = () => {
  return (
    <>
      <GetStart />
    </>
  );
};

export default ActiveDirectorySetup;
