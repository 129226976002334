import React from "react";

import { Button, TableBody, TableCell, TableRow } from "@mui/material";
import SnackBarButton from "../../../components/SnackBarButton";
import CountdownTimer from "../../../components/CountdownTimer";
import styled from "styled-components";

const StyledTableCell = styled(TableCell)({
  fontSize: "16px",
  textAlign: "center",
});

const DetailsTableBody = ({
  filteredTenants,
  generateApiKey,
  setTenantList,
  tenantList,
  selectedTenantName,
  deleteApiKey,
  apiKeyLoading,
  deleteTenant,
}) => {
  return (
    <TableBody>
      {filteredTenants?.map((tenant) => (
        <TableRow
          key={tenant.tenantName}
          sx={{
            border: 1,
          }}
        >
          <StyledTableCell>{tenant.tenantName}</StyledTableCell>
          <StyledTableCell>{tenant.numberOfComputers}</StyledTableCell>
          <StyledTableCell>{tenant.numberOfUsers}</StyledTableCell>
          <StyledTableCell>
            <SnackBarButton
              generateApiKey={generateApiKey}
              tenant={tenant}
              apiKey={tenant.apiKey.key}
            />
          </StyledTableCell>
          <StyledTableCell>
            <CountdownTimer
              setTenantList={setTenantList}
              tenantList={tenantList}
              selectedTenantName={selectedTenantName}
              deleteApiKey={deleteApiKey}
              tenant={tenant}
              apiKeyLoading={apiKeyLoading}
            />
          </StyledTableCell>
          <StyledTableCell>
            <Button
              disabled={!tenant.apiKey.key}
              onClick={() => deleteApiKey(tenant)}
              variant="outlined"
              color={"error"}
            >
              Delete API Key
            </Button>
          </StyledTableCell>
          <StyledTableCell>
            <Button
              onClick={() => deleteTenant(tenant.tenantName)}
              variant="outlined"
              color={"error"}
            >
              Delete Tenant
            </Button>
          </StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default DetailsTableBody;
