import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const RadioAuthFilter = ({
  handleFilterResourceValue,
  filterName,
  filterResourceValue,
}) => {
  return (
    <FormControl sx={{ width: "fit-content", paddingLeft: "10px" }}>
      <FormLabel id="demo-radio-buttons-group-label">{filterName}</FormLabel>
      <RadioGroup
        sx={{ display: "flex", flexDirection: "row" }}
        value={filterResourceValue}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <>
          <FormControlLabel
            value="ALL"
            control={<Radio onChange={handleFilterResourceValue} />}
            label="ALL"
          />
          <FormControlLabel
            value="ALLOWED"
            control={<Radio onChange={handleFilterResourceValue} />}
            label="ALLOWED"
          />
        </>
        <FormControlLabel
          value="DENIED"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="DENIED"
        />
        <FormControlLabel
          value="TIMED"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="TIMED"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioAuthFilter;
