import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const GatewayDevices = ({
  computerName,
  selectedTenant,
  device,
  latestComputer,
}) => {
  const [gatewayListDeviceList, setGatewayListDeviceList] = useState([]);
  const deviceId = latestComputer?.id || device?.id;
  const getGatewayComputers = async (deviceId, tenantName) => {
    try {
      const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
      console.log(response.data);
      if (response.data?.length) {
        const computerList = response.data;
        const configuredGatewayDevices = computerList.filter(
          (computer) => computer.gatewayComputerId === deviceId,
        );
        setGatewayListDeviceList(configuredGatewayDevices);
      } else {
        setGatewayListDeviceList([]);
      }
    } catch (error) {
      setGatewayListDeviceList([]);
    }
  };

  useEffect(() => {
    if (deviceId && selectedTenant) {
      getGatewayComputers(deviceId, selectedTenant);
      const interval = setInterval(() => {
        getGatewayComputers(deviceId, selectedTenant);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [device, selectedTenant, computerName, latestComputer, deviceId]);
  return (
    <>
      {gatewayListDeviceList.length === 0 ? (
        <>
          <Box
            sx={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              display={"flex"}
              alignItems={"center"}
            >
              <Typography variant="body1">
                No manual device configured yet.
              </Typography>
            </Stack>
          </Box>
        </>
      ) : (
        <TableContainer
          style={{
            height: "fit-content",
          }}
          component={Paper}
        >
          <Table
            sx={{
              maxWidth: "80%",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Sl No</TableCell>
                <TableCell align="center">Device Name</TableCell>
                <TableCell align="center">IP Address</TableCell>
                <TableCell align="center">Operating system</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gatewayListDeviceList.map((device, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{device?.dNSHostName}</TableCell>
                  <TableCell align="center">{device?.IPV4Address}</TableCell>
                  <TableCell align="center">{device?.os}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default GatewayDevices;
