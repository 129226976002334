import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import { NGROK } from "../../../../APIs";

const DeviceSelection = ({
  os,
  setActiveDevicesList,
  activeDevicesList,
  selectedTenant,
}) => {
  const [devicesList, setDevicesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    const fetchDevices = async (tenantName) => {
      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/computers`
        );
        const selectedOsDevices =
          response.data && response.data.length
            ? response.data.filter((device) =>
                device.OperatingSystem.toLowerCase().includes(os.toLowerCase())
              )
            : [];
        return selectedOsDevices;
      } catch (error) {
        console.log(error, "set devices lsit error");
        return [];
      }
    };

    const getDevices = async () => {
      const devices = await fetchDevices(selectedTenant.tenantName);
      setDevicesList(devices);
    };
    if (selectedTenant) {
      getDevices();
    }
  }, [os, selectedTenant]);

  if (loading)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <>
      {devicesList.length ? (
        devicesList.map((device) => (
          <FormControlLabel
            key={device.id}
            control={
              <Checkbox
                value={activeDevicesList.includes(device)}
                onChange={() => {
                  if (!activeDevicesList.includes(device)) {
                    setActiveDevicesList((prev) => [...prev, device]);
                  } else {
                    setActiveDevicesList((prev) =>
                      prev.filter(
                        (activeDevice) => activeDevice.id !== device.id
                      )
                    );
                  }
                }}
              />
            }
            label={device.dNSHostName}
          />
        ))
      ) : (
        <Typography variant="h6">
          There are no devices with selected OS
        </Typography>
      )}
    </>
  );
};

export default DeviceSelection;
