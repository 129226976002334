import React, { useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "../../pages.scss";

import MyHeaderTableCell from "../../Components/MyHeaderTableCell";
import styled from "styled-components";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import SearchField from "../../logs/components/SearchField";
import useGeneralSearch from "../../../../hooks/useGeneralSearch";

const MyTableCell = styled(TableCell)`
  font-size: 18px;
`;

const UnitListTable = ({
  selectedUnit,
  setSelectedUnit,
  tenantName,
  unitList,
  setUnitList,
}) => {

  const {
    searchTerm,
    filteredData: filteredUnitList,
    handleSearch,
  } = useGeneralSearch(unitList);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/organization-units`
        );

        setUnitList(response.data);
      } catch (error) {
        setUnitList([]);
        console.error(error);
      }
    };

    fetchUnits();
  }, [setUnitList, tenantName]);

  useEffect(() => {
    if (unitList?.length && !selectedUnit) setSelectedUnit(unitList[0]) 
  }, [selectedUnit, setSelectedUnit, unitList])

  return (
    <TableContainer style={{ height: "fit-content" }} component={Paper}>
      <Table
        sx={{
          width: "100%",
          border: "1px solid #233044",
        }}
        size="large"
      >
        <TableHead>
          <TableRow>
            <MyHeaderTableCell
              sx={{ fontSize: 18 }}
              align={"center"}
              className={"userName"}
            >
              Organization Unit
            </MyHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SearchField
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                width={215}
              />
            </TableCell>
          </TableRow>
          {filteredUnitList?.map((unit) => (
            <TableRow
              className={
                unit.id === selectedUnit?.id
                  ? "userRow activeUserRow"
                  : "userRow"
              }
              key={unit.id}
              sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
              onClick={() => {
                setSelectedUnit(unit);
              }}
            >
              <MyTableCell
                component="td"
                scope="row"
                className="userRowName userName"
                title={unit.cn}
              >
                {unit.cn}
              </MyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UnitListTable;
