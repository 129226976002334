import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { fetchEmails, postEmails } from "../Api";
import useUserStore from "../../../../services/userStore";
import CustomSnackbar from "../../CustomSnackbar";

const EmailNotification = ({ tenantName }) => {
  const userEmail = useUserStore((state) => state.user.email);
  const [emailData, setEmailData] = useState({
    emailToAddress: "",
    emailToDisplay: "",
    isEnabled: false,
  });

  const handleCloseSnackbar = () => {
    setSnackbarSettings((prev) => ({ ...prev, open: false }));
  };

  const handleSnackbar = (open, snackbarMessage, snackbarSeverity, onClose) => {
    setSnackbarSettings({
      open,
      snackbarMessage,
      snackbarSeverity,
      onClose,
    });
  };

  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    snackbarMessage: "",
    snackbarSeverity: "",
    onClose: handleCloseSnackbar,
  });
  const { open, snackbarMessage, snackbarSeverity, onClose } = snackbarSettings;

  const handleChange = (e) => {
    e.preventDefault();

    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };

  const toggleNotifications = async () => {
    setEmailData({ ...emailData, isEnabled: !emailData.isEnabled });

    const { isEnabled } = await postEmails({
      tenantName: tenantName,
      emailToAddress: emailData.emailToAddress,
      emailToDisplay: emailData.emailToDisplay,
      isEnabled: !emailData.isEnabled,
      emailOfAdmin: userEmail,
    });

    setEmailData({ ...emailData, isEnabled });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const emails = await postEmails({
        tenantName: tenantName,
        emailToAddress: emailData.emailToAddress,
        emailToDisplay: emailData.emailToDisplay,
        isEnabled: emailData.isEnabled,
        emailOfAdmin: userEmail,
      });

      if (emails?.length)
        setEmailData({
          isEnabled: emails[0].isEnabled || false,
          emailToAddress: emails[0].emailToAddress || "",
          emailToDisplay: emails[0].emailToDisplay || "",
        });

      handleSnackbar(true, "Success", "success", handleCloseSnackbar);
    } catch (error) {
      handleSnackbar(
        true,
        "Something went wrong",
        "error",
        handleCloseSnackbar
      );
    }
  };

  useEffect(() => {
    const getEmails = async () => {
      const emails = await fetchEmails(tenantName);

      if (emails?.length && emails[emails.length - 1]?.emailToAddress) {
        setEmailData({
          isEnabled: emails[emails.length - 1].isEnabled || false,
          emailToAddress: emails[emails.length - 1].emailToAddress || "",
          emailToDisplay: emails[emails.length - 1].emailToDisplay || "",
        });
      }
    };

    getEmails();
  }, [tenantName]);

  return (
    <>
      <CustomSnackbar
        open={open}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
        onClose={onClose}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ padding: 2 }} align="center" variant="h6">
            Admin email notifications for resource request approvals.
          </Typography>
          <FormControlLabel
            checked={emailData.isEnabled}
            onClick={() => toggleNotifications()}
            control={<Switch />}
            label="Enabled"
          />
        </Stack>
        {emailData?.isEnabled ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
              <InputLabel htmlFor="Email-to-address">Email address</InputLabel>
              <OutlinedInput
                onChange={(e) => handleChange(e)}
                value={emailData.emailToAddress}
                name="emailToAddress"
                color={"secondary"}
                id="Email-to-address"
                sx={{ paddingTop: 2 }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
              <InputLabel htmlFor="Email-to-display">Display name</InputLabel>
              <OutlinedInput
                onChange={(e) => handleChange(e)}
                value={emailData.emailToDisplay}
                name="emailToDisplay"
                color={"secondary"}
                id="Email-to-display"
                sx={{ paddingTop: 2 }}
              />
            </FormControl>
            <Button
              disabled={!emailData.emailToAddress || !emailData.emailToDisplay}
              type="submit"
              variant="outlined"
              sx={{ width: 100, margin: "0 auto" }}
            >
              Submit
            </Button>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default EmailNotification;
