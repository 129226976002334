import React, { useEffect, useState } from "react";
import {
  Link,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Stack,
  Button,
  CardActionArea,
} from "@mui/material";
import { NGROK } from "../../APIs";

import {
  FaLinux,
  FaUbuntu,
  FaRedhat,
  FaWindows,
  FaApple,
} from "react-icons/fa";
import axios from "axios";
import logo from "../../vendor/Whiteswan-New-Logo-White.webp";
import useUserStore from "../../services/userStore";

const Downloads = () => {
  const userData = useUserStore((state) => state.user);
  let userRole = !userData.userRole ? "" : userData.userRole;
  const [windowsVersion, setWindowsVersion] = useState(null);
  const [linuxVersion, setLinuxVersion] = useState(null);
  const [macVersion, setMacVersion] = useState(null);
  const callForWindows = async () => {
    let url = `${NGROK}/api/agent/windows-agent-version`;

    const response = await axios.get(url);
    if (response?.data) {
      setWindowsVersion(response?.data);
    }
  };
  const callForLinux = async () => {
    let url = `${NGROK}/api/agent/linux-agent-version`;

    const response = await axios.get(url);
    if (response?.data) {
      setLinuxVersion(response?.data);
    }
  };
  const callForMac = async () => {
    let url = `${NGROK}/api/agent/mac-agent-version`;

    const response = await axios.get(url);
    if (response?.data) {
      setMacVersion(response?.data);
    }
  };
  useEffect(() => {
    callForWindows();
    callForLinux();
    callForMac();
  }, []);
  return (
    <>
      <Stack spacing={30}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "50%",
              height: "auto",
              backgroundColor: "#233044",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img
              src={logo}
              alt="Whiteswan Logo"
              style={{
                width: "50%",
                height: "auto",
              }}
            />
          </Box>
        </Box>
        <Stack
          direction={"row"}
          spacing={3}
          sx={{
            display: "flex",

            justifyContent: "center",
          }}
        >
          <Card sx={{ width: 350 }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <FaWindows color="#233044" size={100} />
                <Typography
                  fontSize={20}
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Windows{" "}
                  {windowsVersion && (
                    <Typography variant="subtitle1" pl={2}>
                      (v:{windowsVersion})
                    </Typography>
                  )}
                </Typography>
              </Box>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                size="small"
                color="primary"
                component={Link}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                href={`${NGROK}/api/download`}
              >
                <Typography variant="subtitle1" pl={2}>
                  Download WhiteSwanSecurity MSI
                </Typography>
              </Button>
            </CardActions>
          </Card>
          {userRole != "server-pam-admin" ? (
            <Card sx={{ width: 350 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <FaApple color="#233044" size={100} />
                  <Typography
                    fontSize={20}
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Mac{" "}
                    {macVersion && (
                      <Typography variant="subtitle1" pl={2}>
                        (v:{macVersion})
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  component={Link}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  href={`${NGROK}/api/download/macos`}
                >
                  <Typography variant="subtitle1" pl={2}>
                    Download WhiteSwanSecurity PKG
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          ) : null}
          {userRole !== "epam-admin" ? (
            <Card sx={{ width: 350 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <FaLinux color="#233044" size={100} />
                  <Typography
                    fontSize={20}
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Linux{" "}
                    {linuxVersion && (
                      <Typography variant="subtitle1" pl={2}>
                        (v:{linuxVersion})
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    href={`${NGROK}/api/download/ubuntu`}
                  >
                    <FaUbuntu color="#233044" size={25} />
                    <Typography fontSize={14} variant="subtitle1" pl={2}>
                      Download WhiteSwanSecurity DEB
                    </Typography>
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    href={`${NGROK}/api/download/rhel`}
                  >
                    <FaRedhat color="#233044" size={25} />
                    <Typography fontSize={14} variant="subtitle1" pl={2}>
                      Download WhiteSwanSecurity RPM
                    </Typography>
                  </Button>
                </Stack>
              </CardActions>
            </Card>
          ) : null}
        </Stack>
      </Stack>
    </>
  );
};

export default Downloads;
