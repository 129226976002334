import React from "react";
import { Box } from "@mui/system";
import { Button, Chip, TextField } from "@mui/material";

const AddPatternForm = ({
  searchPatternList,
  activeChip,
  handleDeletePattern,
  handleClickChip,
  handleAllMatchesChip,
  patternInputText,
  handlePatternInputChange,
  matchedDevices,
  handleAddPattern,
}) => {
  return (
    <Box component={"fieldset"} borderRadius={1}>
      <legend>Device Name/Pattern</legend>
      {searchPatternList.length ? (
        <Box display={"inline-flex"} flexWrap={"wrap"} borderRadius={1}>
          {searchPatternList.map((pattern) => (
            <Chip
              sx={{ margin: "4px" }}
              key={pattern.id}
              variant={pattern.id === activeChip?.id ? "contained" : "outlined"}
              color="primary"
              label={pattern.text}
              onDelete={() => handleDeletePattern(pattern.id)}
              onClick={() => handleClickChip(pattern)}
            />
          ))}
          <Button
            sx={{ height: 31, margin: "4px" }}
            variant={activeChip?.text === "ALL" ? "contained" : "outlined"}
            onClick={handleAllMatchesChip}
          >
            All matches
          </Button>
        </Box>
      ) : null}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <TextField
          sx={{ marginRight: "10px" }}
          fullWidth
          margin="dense"
          id="name"
          label="Pattern"
          type="text"
          variant="outlined"
          size="small"
          value={patternInputText}
          onChange={(e) => handlePatternInputChange(e)}
        />
        <Button
          disabled={!patternInputText || !matchedDevices.length}
          variant="outlined"
          size={"small"}
          onClick={handleAddPattern}
        >
          Add pattern
        </Button>
      </Box>
    </Box>
  );
};

export default AddPatternForm;
