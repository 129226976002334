import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const ResourceTypeSelection = ({
  activeResourceType,
  setActiveResourceType,
}) => {
  const resourceType = ["Application", "Folder", "Privilege"];
  return (
    <>
      {resourceType.map((resource) => (
        <FormControlLabel
          key={resource}
          control={
            <Checkbox
              value={activeResourceType.includes(resource)}
              onChange={() => {
                if (!activeResourceType.includes(resource)) {
                  setActiveResourceType((prev) => [...prev, resource]);
                } else {
                  setActiveResourceType((prev) =>
                    prev.filter((activeResource) => activeResource !== resource)
                  );
                }
              }}
            />
          }
          label={resource}
        />
      ))}
    </>
  );
};

export default ResourceTypeSelection;
