import React from "react";

import { TableHead, TableRow } from "@mui/material";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";
export const AppsTableHeader = ({ appRef }) => {
  return (
    <TableHead>
      <TableRow ref={appRef}>
        <MyHeaderTableCell align={"center"} className={"userName"}>
          Applications
        </MyHeaderTableCell>

        <MyHeaderTableCell align={"center"} className={"userName"}>
          Privilege Level
        </MyHeaderTableCell>
      </TableRow>
    </TableHead>
  );
};
