import React, { useState } from "react";
import UsersList from "./components/UsersTable";
import { Stack } from "@mui/material";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";
import Computers from "./components/Computers";
import UsersComputersSelection from "./components/UsersComputersSelection";
import UnitListTable from "./components/UnitListTable";

const OrganizationUnit = () => {
  const tenant = myLocalStorage.getItem("latestTenant");
  const [unitList, setUnitList] = useState([]);

  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedItem, setSelectedItem] = useState("Users");

  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Stack spacing={4}>
      <Stack direction={"row"} spacing={2}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
      </Stack>

      <section className="organizationUnitContainer">
        <UnitListTable
          tenantName={selectedTenant?.tenantName}
          unitList={unitList}
          setUnitList={setUnitList}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
        />
        <UsersComputersSelection
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        {selectedItem === "Users" ? (
          <section>
            <UsersList
              unitId={selectedUnit?.id}
              tenantName={selectedTenant?.tenantName}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </section>
        ) : (
          <Computers
            unitId={selectedUnit?.id}
            selectedTenant={selectedTenant}
          />
        )}
      </section>
    </Stack>
  );
};

export default OrganizationUnit;
