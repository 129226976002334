import React, { useState } from "react";
import {
  Grid,
  Link,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import UserService from "../../../../services/UserService";
import { myLocalStorage } from "../../../../components/StorageHelper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SignInEmailCheck = ({
  setRealmName,
  setTenantName,
  email,
  setEmail,
  togleActiveForm,
  toggleEmailActive,
  activeForm,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  const getTenantsInfo = async () => {
    const axiosInstance = axios.create();

    try {
      return await axiosInstance.get(
        `${NGROK}/api/get-tenants?email=${email.trim().toLowerCase()}`,
      );
    } catch (error) {
      setTenantName("");
      console.error(error);
    }
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setEmail(val.toLowerCase().trim());
    formik.handleChange(e);
    setSubmitLoading(false);
    setIsSubmitted(false);
    setEmailErrorMessage("");
    toggleEmailActive();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitSignIn(e);
    }
  };

  const checkIfUserExist = async (email) => {
    const tenants = await getTenantsInfo(email.toLowerCase().trim());

    if (!tenants || !tenants.data?.length) {
      setTenantName("");
      setSubmitLoading(false);
      return false;
    } else if (tenants?.data?.length) {
      setTenantName(tenants?.data[0].tenantName);
      myLocalStorage.setItem("role", tenants?.data[0].role);
      setSubmitLoading(false);
      return tenants?.data[0];
    }
  };

  const keycloakInitAndLogin = async (realm, email) => {
    try {
      setLoading(true);
      myLocalStorage.setItem("email", email.toLowerCase().trim());
      myLocalStorage.setItem("realm", realm);

      UserService.initKeycloak(realm);
      setTimeout(() => {
        UserService.doLogin(email.toLowerCase().trim());
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const signInAsSysAdmin = async (user) => {
    if (!formik.errors.email && email) {
      if (user) {
        myLocalStorage.setItem("email", email.toLowerCase().trim());

        setTenantName(user.realmName);
        setRealmName(user.legacyRealm.realmName.toLowerCase());

        togleActiveForm();
      }
    }
    setIsSubmitted(true);
  };

  const submitSignIn = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    setIsSubmitted(true);
    formik.handleSubmit();
    setEmailErrorMessage("");
    if (!formik.errors.email && email) {
      const user = await checkIfUserExist(email.toLowerCase().trim());
      console.log(user);
      if (!user) {
        setEmailErrorMessage(<Typography>This user doesn't exist.</Typography>);
        return;
      } else {
        if (user && user.role === "SYS_ADMIN") {
          signInAsSysAdmin(user);
          return;
        }

        if (user.realm?.keycloakAccountExists) {
          const realm =
            user.role === "TENANT_USER"
              ? user.realm.realmName
              : "whiteswan_tenants";
          keycloakInitAndLogin(realm, email.toLowerCase().trim());
        } else if (user.legacyRealm?.keycloakAccountExists) {
          const realm =
            user.role === "TENANT_USER"
              ? user.legacyRealm.realmName
              : "whiteswan_tenants_legacy";
          keycloakInitAndLogin(realm, email.toLowerCase().trim());
        } else {
          setEmailErrorMessage(
            <Typography>This user doesn't exist.</Typography>,
          );
          return;
        }
      }
    }
    setIsSubmitted(true);
    setSubmitLoading(false);
  };

  if (loading)
    return (
      <Box
        p={5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          maxHeight: 400,
          minHeight: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
      <TextField
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {submitLoading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  variant="contained"
                  onClick={submitSignIn}
                  disabled={activeForm.isSignInActive}
                  sx={{
                    borderRadius: "50%",
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {formik.errors.email && isSubmitted ? (
        <Alert severity="error">{formik.errors.email}</Alert>
      ) : null}
      {emailErrorMessage && isSubmitted ? (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {emailErrorMessage}
        </Alert>
      ) : null}
    </Box>
  );
};

export default SignInEmailCheck;
