import React from "react";

import {

  TableCell,

  TableHead,
  TableRow,

} from "@mui/material";


const  RequestsTableHead = () => {
  return (
    <TableHead
      sx={{
        backgroundColor: "#233044",
      }}
    >
      <TableRow>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Created At
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Requested By
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Computer Name
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Resource Category
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Resource Name
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Justification
        </TableCell>
        <TableCell
          sx={{
            color: "white",
            fontSize: "16px",
          }}
          align="center"
        >
          Privilege Level
        </TableCell>
        <TableCell
          align="center"
          colSpan={2}
          sx={{
            color: "white",
            fontSize: "16px",
          }}
        >
          Status
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default RequestsTableHead;
