import { createContext } from "react";
import qs from "qs";
import axios from "axios";
import { NGROK } from "../APIs";
import { myLocalStorage } from "../components/StorageHelper";

const axiosInstance = axios.create();

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  
  const signIn = async ({ username, password, realmName, clientId }) => {
    const role = myLocalStorage.getItem("role");
    const ngrokWithoutProtocol = NGROK.slice(8);
    const url = `https://auth-${ngrokWithoutProtocol}/realms/${realmName}/protocol/openid-connect/`;

    try {
      let body = {
        grant_type: "password",
        client_id: clientId,
        username,
        password,
        scope: "openid",
      };
      body = qs.stringify(body);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url + "token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: body,
      };

      const { data } = await axiosInstance.request(config);
      config = {
        method: "get",
        maxBodyLength: Infinity,
        url: url + "userinfo",
        headers: {
          Authorization: `Bearer ${data.access_token}`,
        },
      };

      myLocalStorage.setItem("realm", realmName);
      const response = await axiosInstance.request(config);

      return {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        userInfo: { ...response.data, role },
        id_token: data.id_token,
      };
    } catch (e) {
      console.error("Error during sign-in:", e);
      throw e;
    }
  };

  const signOut = async ({
    realm,
    client_id,
    access_token,
    refresh_token,
  }) => {
    const ngrokWithoutProtocol = NGROK.slice(8);
    const url = `https://auth-${ngrokWithoutProtocol}/realms/${realm}/protocol/openid-connect/logout`;

    let body = {
      client_id,
      refresh_token,
    };
    body = qs.stringify(body);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: body,
    };

    await axios.request(config);

  };

  const signUp = async (tenantName, email, fullName) => {
    try {
      return await axiosInstance.post(`${NGROK}/api/auth/registration`, {
        tenantName,
        fullName: fullName,
        username: email,
        password: email,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
