import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function CustomSnackbar({ open, snackbarMessage, snackbarSeverity, onClose }) {

  return (
    <Snackbar
      color="blue"
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <MuiAlert severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
  );
}

export default CustomSnackbar;
