import React from "react";
import EndpointsResources from "../../EndpointsResources/EndpointsResources";

const DeviceResourcesDetails = ({ device, selectedTenant }) => {
  return (
    <>
      <EndpointsResources
        selectedTenantName={selectedTenant}
        activeComputerData={device}
        deviceHasAccessData={device.hasAccess}
        selectedOSData={device.OperatingSystem}
      />
    </>
  );
};

export default DeviceResourcesDetails;
