import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useUserStore from "../../../../services/userStore";
import { fetchTenantsData } from "../../api";
import { setLocalStorageTenant } from "../../../../services/Helpers";

const TenantSelection = ({
  selectedTenant,
  setSelectedTenant,
  setProfilesList,
}) => {
  const userEmail = useUserStore((state) => state.user.email);

  const [tenantsList, setTenantsList] = useState([]);

  useEffect(() => {
    fetchTenantsData(userEmail, setTenantsList);
  }, [userEmail]);
  
  return (
    <>
      {tenantsList?.length > 1 ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenant ? selectedTenant?.tenantName : " "}
          options={tenantsList.map((tenant) => tenant.tenantName)}
          sx={{ width: 300, backgroundColor: "white" }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedTenant(undefined);
              setProfilesList([]);
            }
            const tenant = tenantsList.find(
              (tenant) => tenant.tenantName === value
            );
            if (tenant !== undefined) {
              setSelectedTenant(tenant);
              setLocalStorageTenant(tenant)
            }
          }}
        />
      ) : null}
    </>
  );
};

export default TenantSelection;
