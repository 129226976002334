import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Button,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useUserStore from "../../../services/userStore";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import SearchField from "./components/SearchField";
import useDateFilter from "../../../hooks/useDateFilter";
import { fetchTenantsData } from "../api";
import { handleGeneratePDF } from "./components/helpers";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";
import { setLocalStorageTenant } from "../../../services/Helpers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs from "dayjs";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import { formatDateTime } from "../../../GenericMethods";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";

const AuditLogs = () => {
  const tenant = myLocalStorage.getItem("latestTenant");
  const userData = useUserStore((state) => state.user);

  const userEmail = useUserStore((state) => state.user.email);

  const [pageOffset, setPageOffset] = useState(0);

  const [tenantsList, setTenantsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const tableRef = useRef();

  const [devicesList, setDevicesList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [progress, setProgress] = useState(false);
  const [tableMaxHeight, setTableMaxHeight] = useState("100%");
  const [tableMaxWidth, setTableMaxWidth] = useState("100%");

  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [filterPagination, setFilterPagination] = useState("lw");
  const [customMode, setCustomMode] = useState(false);
  const [startEndDate, setStartEndDate] = useState([dayjs(), dayjs()]);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [reachedEnd, setReachedEnd] = useState(false);
  const forbiddenSearchFields = ["bucket", "tenantName", "_measurement"];
  const { searchTerm, filteredData, handleSearch } = useGeneralSearch(
    auditLogs,
    null,
    forbiddenSearchFields,
  );

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(filteredData, "time");
  let userRole = !userData.userRoleId ? "-1" : userData.userRoleId;

  const fetchDevices = async (tenantName) => {
    try {
      const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
      setDevicesList(response.data);
    } catch (error) {
      console.log(error, "set devices lsit error");
    }
  };
  const fetchAuditLogs = async () => {
    const bucket = "ondevice-auditlog";
    try {
      let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&tenantName=${selectedTenant?.tenantName}&timingMode=${filterPagination}&userRole=${userRole}`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}&limit=100`;
      }
      url += `&offset=${pageOffset}`;
      if (selectedDevice !== null) {
        url += `&isDeviceSpecific=true&deviceName=${selectedDevice?.dNSHostName}`;
      }
      const response = await axios.get(url);
      /* const tenantLogs =
        response?.data?.filter(
          (log) => log.tenantName === selectedTenant?.tenantName
        ) || []; */

      if (response?.data.length > 0) {
        let auditData =
          auditLogs !== null && auditLogs?.length > 0
            ? pageOffset === 0
              ? []
              : auditLogs
            : [];
        let newSet = [...auditData, ...response?.data];

        setAuditLogs(newSet);
      } else {
        setReachedEnd(true);
      }

      setLoading(false);
      setLoadingData(false);
      setProgress(false);
      setLoadingCount(response?.data?.length > 1);
    } catch (error) {
      console.error(error, "error fetchAuditLogs ");
      setLoading(false);
      setProgress(false);
    }
  };

  useEffect(() => {
    fetchAuditLogs();
  }, [
    selectedTenant?.tenantName,
    filterPagination,
    customEndDate,
    customStartDate,
    customMode,
    pageOffset,
    selectedDevice,
  ]);

  useEffect(() => {
    fetchTenantsData(userEmail, setTenantsList);
  }, [userEmail]);

  useEffect(() => {
    if (!selectedTenant && tenantsList?.length) {
      setSelectedTenant(tenantsList[0]);
      setLocalStorageTenant(tenantsList[0]);
    }
  }, [selectedTenant, tenantsList]);

  useEffect(() => {
    if (selectedTenant) fetchDevices(selectedTenant.tenantName);
  }, [selectedTenant]);

  const filterLogs = (logs, tenantName, deviceName = "") => {
    const filteredLogs = logs?.filter((log) =>
      !deviceName
        ? log.tenantName === tenantName
        : log.tenantName === tenantName && log.computerName === deviceName,
    );

    const getTimestamp = (obj) => obj.timestamp || obj._time;
    const sortByDate = (a, b) => {
      const timestampA = moment(getTimestamp(a)).valueOf();
      const timestampB = moment(getTimestamp(b)).valueOf();
      return timestampB - timestampA;
    };
    return filteredLogs.sort(sortByDate);
  };

  const deviceAuditLogs = selectedTenant
    ? filterLogs(logs, selectedTenant.tenantName, selectedDevice?.dNSHostName)
    : [];

  const loadMore = () => {
    if (!reachedEnd) {
      setLoadingData(true);
      let count = pageOffset;
      setPageOffset(count + 100);
    }
  };

  const handleChangeFilterDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };
  const dataPagination = [
    { label: "Last 24 hours", value: "24Hrs" },
    { label: "Last 7 days ", value: "lw" },
    { label: "Last 30 days", value: "lm" },
    { label: "Last 90 days", value: "l3m" },
    { label: "Custom Range", value: "customDate" },
  ];
  const handleFilterPagination = (val) => {
    setPageOffset(0);
    setProgress(true);
    setCustomMode(false);
    setReachedEnd(false);
    setAuditLogs([]);
    if (val !== null) {
      let value = dataPagination
        .filter((data) => data.label === val)
        .map((val) => val.value);
      if (value[0] === "customDate") {
        setCustomMode(true);
        const currentDate = new Date();
        const nextWeekDate = new Date(currentDate);
        nextWeekDate.setDate(currentDate.getDate() - 7);
        handleChangeFilterDateRange([nextWeekDate, currentDate]);
      }
      getDatesForFilter(value[0]);
      setFilterPagination(value[0]);
    } else {
      getDatesForFilter("lw");
      setFilterPagination("lw");
    }
  };

  const getDatesForFilter = (mode) => {
    let today = new Date();
    switch (mode) {
      case "lm":
        let last30DaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30,
        );
        setStartEndDate([dayjs(last30DaysStart), dayjs(today)]);
        break;
      case "l3m":
        let last90DaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 90,
        );
        setStartEndDate([dayjs(last90DaysStart), dayjs(today)]);
        break;
      default:
        let last7DaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7,
        );
        setStartEndDate([dayjs(last7DaysStart), dayjs(today)]);
        break;
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = tableRef.current;
    if (scrollTop + clientHeight >= scrollHeight && !loadingData) {
      loadMore();
    }
  };
  useLayoutEffect(() => {
    if (tableRef.current) {
      const screenHeight = window.innerHeight;
      const rect = tableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top + window.pageYOffset;
      let maxHeight = screenHeight - (distanceFromTop + 35);
      setTableMaxWidth(window.innerWidth + "px");
      setTableMaxHeight(maxHeight + "px");
      //tableRef.current.style.maxHeight = `${maxHeight}px`;
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef.current]);

  const pageIdle = () => {
    fetchAuditLogs();
    activate();
  };

  const { activate } = useIdleTimer({
    timeout: 1000 * 10,
    onIdle: pageIdle,
    onActive: () => {},
    onAction: () => {},
    debounce: 500,
  });

  if (loading) return <CircularProgress />;

  return (
    <IdleTimerProvider>
      <Stack spacing={4}>
        <Stack spacing={2} direction={"row"}>
          <TenantSelection
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
          />

          <Autocomplete
            disablePortal
            value={selectedDevice ? selectedDevice.dNSHostName : "All devices"}
            options={[
              "All devices",
              ...devicesList.map((device) => device.dNSHostName),
            ]}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Device List" />
            )}
            onChange={(e, value) => {
              if (!value) {
                setSelectedDevice(null);
              } else if (value === "All devices") {
                setSelectedDevice(null);
                return;
              }
              const device = devicesList.find(
                (device) => device.dNSHostName === value,
              );
              if (device !== undefined) {
                setSelectedDevice(device);
              }
              setAuditLogs([]);
              setProgress(true);
              setLoadingData(true);
              setReachedEnd(false);
              setLoadingCount(false);
              setPageOffset(0);
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "15px",
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={dataPagination.map((value) => value.label)}
              value={
                dataPagination
                  .filter((data) => data.value === filterPagination)
                  .map((val) => val.label)[0]
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Time Duration" />
              )}
              onChange={(e, value) => {
                handleFilterPagination(value);
              }}
            />
            {customMode ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    startText="Start date"
                    endText="End date"
                    maxDate={new Date()}
                    value={startEndDate}
                    onChange={(e) => {
                      setAuditLogs([]);
                      setProgress(true);
                      setLoadingData(true);
                      setReachedEnd(false);
                      setLoadingCount(false);
                      setPageOffset(0);
                      handleChangeFilterDateRange(e);
                      handleChangeDateRange(e);
                    }}
                    renderInput={(startProps, endProps) => (
                      <Stack direction={"row"} spacing={3}>
                        <TextField {...startProps} />
                        <TextField {...endProps} />
                      </Stack>
                    )}
                  />
                </LocalizationProvider>
              </>
            ) : null}
          </Box>
        </Stack>

        <Stack direction={"row"} spacing={2}>
          {/*  <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        /> */}
          <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
          <Button
            variant="outlined"
            onClick={() =>
              handleGeneratePDF(dateRange, deviceAuditLogs, searchTerm)
            }
            disabled={deviceAuditLogs.length === 0}
          >
            Download Report
          </Button>
        </Stack>

        {progress ? (
          <CircularProgress />
        ) : deviceAuditLogs.length ? (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid #233044",
            }}
          >
            <TableContainer
              ref={tableRef}
              sx={{
                maxHeight: tableMaxHeight,
                maxWidth: tableMaxWidth,
              }}
            >
              <Table
                stickyHeader
                size="small"
                aria-label="a dense table"
                sx={{
                  "& td, & th": {
                    border: "1px solid #233044",
                  },
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: "#233044",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        backgroundColor: "#233044",
                      }}
                      align="center"
                    >
                      Time
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        backgroundColor: "#233044",
                      }}
                      align="center"
                    >
                      Computer User
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        backgroundColor: "#233044",
                      }}
                      align="center"
                    >
                      Computer Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        backgroundColor: "#233044",
                      }}
                      align="center"
                    >
                      Type
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        backgroundColor: "#233044",
                      }}
                      align="center"
                    >
                      Resource Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        backgroundColor: "#233044",
                      }}
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceAuditLogs.map((log, index) => (
                    <TableRow
                      key={`${log.timestamp}${log.message}${log._time}${index}`}
                    >
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {moment(
                          log.timestamp !== undefined && log.timestamp !== null
                            ? log.timestamp
                            : log._time,
                        ).format("DD.MM.YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {log.computerUserEmail}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {log.computerName}
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px" }} align="center">
                        {log.resourceType}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          maxWidth: "300px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                        align="center"
                      >
                        {log.resourceName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          maxWidth: "700px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                        align="center"
                      >
                        {log.message}
                      </TableCell>
                    </TableRow>
                  ))}
                  {loadingData && !reachedEnd && (
                    <TableRow
                      sx={{
                        border: 1,
                      }}
                    >
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : selectedTenant ? (
          <Typography fontSize={16} p={5}>
            There are no logs related to selected Tenant or Device
          </Typography>
        ) : (
          <Typography fontSize={16} p={5}>
            Select Tenant
          </Typography>
        )}
      </Stack>
    </IdleTimerProvider>
  );
};

export default AuditLogs;
