import axios from "axios";

const fetchCache = new Map();

const UrlFetchManager = async (url, config = {}) => {
  if (fetchCache.has(url)) {
    const response = fetchCache.get(url);
    if (response.status) {
      fetchCache.delete(url);
    } else {
      return fetchCache.get(url);
    }
  }

  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => {
      reject(new Error("Request timed out"));
    }, 12000),
  );

  const fetchPromise = axios
    .get(url, config)
    .then((response) => {
      fetchCache.set(url, response);
      return response;
    })
    .catch((error) => {
      console.log(`Error fetching ${url}:`, error);
      throw error;
    });

  try {
    const result = await Promise.race([fetchPromise, timeoutPromise]);
    return result;
  } catch (error) {
    fetchCache.delete(url);
    throw error;
  }
};

export default UrlFetchManager;
