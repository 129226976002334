import axios from "axios";
import { NGROK } from "../APIs";

export const channel = new BroadcastChannel("logout");

export const logOut = async (email) => {
  channel.postMessage({ logoutMessage: "logout" });
  try {
    axios.get(`${NGROK}/api/logout`);
  } catch (error) {
    console.error(error);
  }
};

export const getClientId = (realm) => {
  const realmCLientId = {
    whiteswan_tenants: "otp-auth",
    whiteswan_tenants_initial: "otp-auth-initial",
    whiteswan_tenants_legacy: "otp-auth-legacy",
  };
  return realmCLientId[realm] || `otp-auth-${realm}`;
};
