import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Checkbox,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";
import "../../pages.scss";
import SearchField from "../../logs/components/SearchField";
import useGeneralSearch from "../../../../hooks/useGeneralSearch";

const UsersSelectionList = ({ setSelectedUser, userId, tenantName }) => {
  const [usersList, setUsersList] = useState([]);
  const [showServiceAccountsOnly, setShowServiceAccountsOnly] = useState(false);

  const {
    searchTerm,
    filteredData: filteredUsers,
    handleSearch,
  } = useGeneralSearch(usersList);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${NGROK}/api/${tenantName}/users`);
        setUsersList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, [tenantName]);

  useEffect(() => {
    if (!userId && usersList?.length) setSelectedUser(usersList[0]);
  }, [setSelectedUser, userId, usersList]);

  const handleCheckbox = (event) => {
    setShowServiceAccountsOnly(event.target.checked);
  };

  const users = showServiceAccountsOnly
    ? filteredUsers?.filter((user) => user.isServiceAccount)
    : filteredUsers;

  return (
    <TableContainer
      style={{ height: "fit-content", maxWidth: 400, minWidth: 250 }}
      component={Paper}
    >
      <Table
        sx={{
          width: "100%",
          border: "1px solid #233044",
        }}
        size="large"
      >
        <TableHead>
          <TableRow>
            <MyHeaderTableCell
              align="center"
              className="userName"
              sx={{ color: "white", backgroundColor: "#233044", fontSize: 18 }}
            >
              Users
            </MyHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SearchField
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                width={215}
              />
            </TableCell>
            <TableCell
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={showServiceAccountsOnly}
                onChange={(e) => handleCheckbox(e)}
              />
              <Typography fontSize={14}>Show service accounts only</Typography>
            </TableCell>
          </TableRow>
          {users?.map((user) => (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
              className={
                user.id === userId ? "userRow activeUserRow" : "userRow"
              }
              key={user.Name}
            >
              <TableCell
                component="td"
                scope="row"
                className="userRowName userName"
                title={user.Name}
                onClick={() => {
                  setSelectedUser(user);
                }}
              >
                <Typography fontSize={18} className="userRowName userName">
                  {user.Name}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersSelectionList;
