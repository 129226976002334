import styled from "styled-components";
import Logo from "../vendor/Whiteswan-Security-logo.png";

export const MainLogo = styled.img.attrs({ src: `${Logo}` })`
  margin-right: ${(props) => props.theme.spacing(2)};
  width: 220px;
  height: 65px;
  vertical-align: middle;
  display: inline;
  padding: 5px;
`;
