import React from "react";
import {
  Typography,
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const ServiceTerms = () => {
  return (
    <Box maxWidth="800px" mx="auto" p={3}>
      <Typography variant="h4" gutterBottom>
        END USER LICENSE AGREEMENT
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last updated January 01, 2024
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whiteswan Security Platform is licensed to You (End-User) by Whiteswan
        Identity Security , Corp, located at 333 W San Carlos St Suit 600 c/o
        Whiteswan Identity Security , San Jose, CA 95110, USA, San Jose,
        California 95110, United States ("Licensor"), for use only under the
        terms of this License Agreement. We are registered in California, United
        States and have our registered office at 1312 North Road, Belmont, CA
        94002.
        <br />
        <br /> By downloading the Licensed Application from , and any update
        thereto (as permitted by this License Agreement), You indicate that You
        agree to be bound by all of the terms and conditions of this License
        Agreement, and that You accept this License Agreement. referred to in
        this License Agreement as "Services."
        <br />
        <br /> The parties of this License Agreement acknowledge that the
        Services are not a Party to this License Agreement and are not bound by
        any provisions or obligations with regard to the Licensed Application,
        such as warranty, liability, maintenance and support thereof. Whiteswan
        Identity Security , Corp, not the Services, is solely responsible for
        the Licensed Application and the content thereof.
        <br />
        <br /> This License Agreement may not provide for usage rules for the
        Licensed Application that are in conflict with the latest ("Usage
        Rules").
        <br />
        <br /> Whiteswan Identity Security , Corp acknowledges that it had the
        opportunity to review the Usage Rules and this License Agreement is not
        conflicting with them. Whiteswan Security Platform when purchased or
        downloaded through the Services, is licensed to You for use only under
        the terms of this License Agreement. The Licensor reserves all rights
        not expressly granted to You. Whiteswan Security Platform is to be used
        on devices that operate with .
      </Typography>
      <br />
      <Typography variant="h5" gutterBottom>
        TABLE OF CONTENTS
      </Typography>
      <List component="ol" aria-label="table of contents" dense>
        <ListItem>
          <Link href="#the-application">
            <ListItemText primary="1. THE APPLICATION" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#scope-of-license">
            <ListItemText primary="2. SCOPE OF LICENSE" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#technical-requirements">
            <ListItemText primary="3. TECHNICAL REQUIREMENTS" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#maintenance-and-support">
            <ListItemText primary="4. MAINTENANCE AND SUPPORT" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#use-of-data">
            <ListItemText primary="5. USE OF DATA" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#user-generated-contributions">
            <ListItemText primary="6. USER-GENERATED CONTRIBUTIONS" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#contribution-license">
            <ListItemText primary="7. CONTRIBUTION LICENSE" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#liability">
            <ListItemText primary="8. LIABILITY" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#warranty">
            <ListItemText primary="9. WARRANTY" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#product-claims">
            <ListItemText primary="10. PRODUCT CLAIMS" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#legal-compliance">
            <ListItemText primary="11. LEGAL COMPLIANCE" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#contact-information">
            <ListItemText primary="12. CONTACT INFORMATION" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#termination">
            <ListItemText primary="13. TERMINATION" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#third-party-terms-of-agreements-and-beneficiary">
            <ListItemText primary="14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#intellectual-property-rights">
            <ListItemText primary="15. INTELLECTUAL PROPERTY RIGHTS" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#applicable-law">
            <ListItemText primary="16. APPLICABLE LAW" />
          </Link>
        </ListItem>
        <ListItem>
          <Link href="#miscellaneous">
            <ListItemText primary="17. MISCELLANEOUS" />
          </Link>
        </ListItem>
      </List>
      <br />
      <Typography id="the-application" variant="h6" gutterBottom>
        1. THE APPLICATION
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whiteswan Security Platform ("Licensed Application") is a piece of
        software created to Facilitate connecting users and devices to
        authorized corporate applications and infrastructure — and customized
        for mobile devices ("Devices"). It is used to grant time based elevation
        of privileges for users to complete their tasks and to allow the users
        to connect to their authorized corporate applications and
        infrastructure.
        <br />
        <br />
        The Licensed Application is not tailored to comply with
        industry-specific regulations (Health Insurance Portability and
        Accountability Act (HIPAA), Federal Information Security Management Act
        (FISMA), etc.), so if your interactions would be subjected to such laws,
        you may not use this Licensed Application. You may not use the Licensed
        Application in a way that would violate the Gramm-Leach-Bliley Act
        (GLBA).
      </Typography>
      <br />
      <Typography id="scope-of-license" variant="h6" gutterBottom>
        2. SCOPE OF LICENSE
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.1 You may not reverse engineer, translate, disassemble, integrate,
        decompile, remove, modify, combine, create derivative works or updates
        of, adapt, or attempt to derive the source code of the Licensed
        Application, or any part thereof (except with Whiteswan Identity
        Security , Corp's prior written consent).
        <br />
        <br />
        2.2 Violations of the obligations mentioned above, as well as the
        attempt of such infringement, may be subject to prosecution and damages.
        <br />
        <br />
        2.3 Licensor reserves the right to modify the terms and conditions of
        licensing.
        <br />
        <br />
        2.4 Nothing in this license should be interpreted to restrict
        third-party terms. When using the Licensed Application, You must ensure
        that You comply with applicable third-party terms and conditions.
      </Typography>
      <br />
      <Typography id="technical-requirements" variant="h6" gutterBottom>
        3. TECHNICAL REQUIREMENTS
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.1 Licensor attempts to keep the Licensed Application updated so that
        it complies with modified/new versions of the firmware and new hardware.
        You are not granted rights to claim such an update.
        <br />
        <br />
        3.2 You acknowledge that it is Your responsibility to confirm and
        determine that the app end-user device on which You intend to use the
        Licensed Application satisfies the technical specifications mentioned
        above.
        <br />
        <br />
        3.3 Licensor reserves the right to modify the technical specifications
        as it sees appropriate at any time.
      </Typography>
      <br />{" "}
      <Typography id="maintenance-and-support" variant="h6" gutterBottom>
        4. MAINTENANCE AND SUPPORT
      </Typography>
      <Typography variant="body1" gutterBottom>
        4.1 The Licensor is solely responsible for providing any maintenance and
        support services for this Licensed Application. You can reach the
        Licensor at the email address listed in the Overview for this Licensed
        Application. <br />
        <br />
        4.2 Whiteswan Identity Security , Corp and the End-User acknowledge that
        the Services have no obligation whatsoever to furnish any maintenance
        and support services with respect to the Licensed Application.
      </Typography>
      <br />{" "}
      <Typography id="use-of-data" variant="h6" gutterBottom>
        5. USE OF DATA
      </Typography>
      <Typography variant="body1" gutterBottom>
        You acknowledge that Licensor will be able to access and adjust Your
        downloaded Licensed Application content and Your personal information,
        and that Licensor's use of such material and information is subject to
        Your legal agreements with Licensor and Licensor's privacy policy:
        https://app.termly.io/document/privacy-policy/163a8bb0-3668-4bf5-933f-a1b62dd6ab44.
        <br />
        <br />
        You acknowledge that the Licensor may periodically collect and use
        technical data and related information about your device, system, and
        application software, and peripherals, offer product support, facilitate
        the software updates, and for purposes of providing other services to
        you (if any) related to the Licensed Application. Licensor may also use
        this information to improve its products or to provide services or
        technologies to you, as long as it is in a form that does not personally
        identify you.
      </Typography>
      <br />{" "}
      <Typography id="user-generated-contributions" variant="h6" gutterBottom>
        6. USER-GENERATED CONTRIBUTIONS
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Licensed Application does not offer users to submit or post content.
        We may provide you with the opportunity to create, submit, post,
        display, transmit, perform, publish, distribute, or broadcast content
        and materials to us or in the Licensed Application, including but not
        limited to text, writings, video, audio, photographs, graphics,
        comments, suggestions, or personal information or other material
        (collectively, "Contributions"). Contributions may be viewable by other
        users of the Licensed Application and through third-party websites or
        applications. As such, any Contributions you transmit may be treated in
        accordance with the Licensed Application Privacy Policy. When you create
        or make available any Contributions, you thereby represent and warrant
        that: <br />
        <br /> 1. The creation, distribution, transmission, public display, or
        performance, and the accessing, downloading, or copying of your
        Contributions do not and will not infringe the proprietary rights,
        including but not limited to the copyright, patent, trademark, trade
        secret, or moral rights of any third party.
        <br /> 2. You are the creator and owner of or have the necessary
        licenses, rights, consents, releases, and permissions to use and to
        authorize us, the Licensed Application, and other users of the Licensed
        Application to use your Contributions in any manner contemplated by the
        Licensed Application and this License Agreement. <br />
        3. You have the written consent, release, and/or permission of each and
        every identifiable individual person in your Contributions to use the
        name or likeness or each and every such identifiable individual person
        to enable inclusion and use of your Contributions in any manner
        contemplated by the Licensed Application and this License Agreement.
        <br /> 4. Your Contributions are not false, inaccurate, or misleading.{" "}
        <br />
        5. Your Contributions are not unsolicited or unauthorized advertising,
        promotional materials, pyramid schemes, chain letters, spam, mass
        mailings, or other forms of solicitation. <br />
        6. Your Contributions are not obscene, lewd, lascivious, filthy,
        violent, harassing, libelous, slanderous, or otherwise objectionable (as
        determined by us). <br />
        7. Your Contributions do not ridicule, mock, disparage, intimidate, or
        abuse anyone.
        <br /> 8. Your Contributions are not used to harass or threaten (in the
        legal sense of those terms) any other person and to promote violence
        against a specific person or class of people. <br />
        9. Your Contributions do not violate any applicable law, regulation, or
        rule. <br />
        10. Your Contributions do not violate the privacy or publicity rights of
        any third party.
        <br /> 11. Your Contributions do not violate any applicable law
        concerning child pornography, or otherwise intended to protect the
        health or well-being of minors. <br />
        12. Your Contributions do not include any offensive comments that are
        connected to race, national origin, gender, sexual preference, or
        physical handicap. <br />
        13. Your Contributions do not otherwise violate, or link to material
        that violates, any provision of this License Agreement, or any
        applicable law or regulation. Any use of the Licensed Application in
        violation of the foregoing violates this License Agreement and may
        result in, among other things, termination or suspension of your rights
        to use the Licensed Application.
      </Typography>
      <br />{" "}
      <Typography id="contribution-license" variant="h6" gutterBottom>
        7. CONTRIBUTION LICENSE
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree that we may access, store, process, and use any information
        and personal data that you provide following the terms of the Privacy
        Policy and your choices (including settings). <br />
        <br />
        By submitting suggestions of other feedback regarding the Licensed
        Application, you agree that we can use and share such feedback for any
        purpose without compensation to you. <br />
        <br />
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area in the Licensed Application.
        You are solely responsible for your Contributions to the Licensed
        Application and you expressly agree to exonerate us from any and all
        responsibility and to refrain from any legal action against us regarding
        your Contributions.
      </Typography>
      <br />{" "}
      <Typography id="liability" variant="h6" gutterBottom>
        8. LIABILITY
      </Typography>
      <Typography variant="body1" gutterBottom>
        8.1 Licensor's responsibility in the case of violation of obligations
        and tort shall be limited to intent and gross negligence. Only in case
        of a breach of essential contractual duties (cardinal obligations),
        Licensor shall also be liable in case of slight negligence. In any case,
        liability shall be limited to the foreseeable, contractually typical
        damages. The limitation mentioned above does not apply to injuries to
        life, limb, or health. <br />
        <br />
        8.2 Licensor takes no accountability or responsibility for any damages
        caused due to a breach of duties according to Section 2 of this License
        Agreement. To avoid data loss, You are required to make use of backup
        functions of the Licensed Application to the extent allowed by
        applicable third-party terms and conditions of use. You are aware that
        in case of alterations or manipulations of the Licensed Application, You
        will not have access to the Licensed Application.
      </Typography>
      <br />{" "}
      <Typography id="warranty" variant="h6" gutterBottom>
        9. WARRANTY
      </Typography>
      <Typography variant="body1" gutterBottom>
        9.1 Licensor warrants that the Licensed Application is free of spyware,
        trojan horses, viruses, or any other malware at the time of Your
        download. Licensor warrants that the Licensed Application works as
        described in the user documentation. <br />
        <br />
        9.2 No warranty is provided for the Licensed Application that is not
        executable on the device, that has been unauthorizedly modified, handled
        inappropriately or culpably, combined or installed with inappropriate
        hardware or software, used with inappropriate accessories, regardless if
        by Yourself or by third parties, or if there are any other reasons
        outside of Whiteswan Identity Security , Corp's sphere of influence that
        affect the executability of the Licensed Application. <br />
        <br />
        9.3 You are required to inspect the Licensed Application immediately
        after installing it and notify Whiteswan Identity Security , Corp about
        issues discovered without delay by email provided in Contact
        Information. The defect report will be taken into consideration and
        further investigated if it has been emailed within a period of ninety
        (90) days after discovery. <br />
        <br />
        9.4 If we confirm that the Licensed Application is defective, Whiteswan
        Identity Security , Corp reserves a choice to remedy the situation
        either by means of solving the defect or substitute delivery. <br />
        <br />
        9.5 In the event of any failure of the Licensed Application to conform
        to any applicable warranty, You may notify the Services Store Operator,
        and Your Licensed Application purchase price will be refunded to You. To
        the maximum extent permitted by applicable law, the Services Store
        Operator will have no other warranty obligation whatsoever with respect
        to the Licensed Application, and any other losses, claims, damages,
        liabilities, expenses, and costs attributable to any negligence to
        adhere to any warranty. <br />
        <br />
        9.6 If the user is an entrepreneur, any claim based on faults expires
        after a statutory period of limitation amounting to twelve (12) months
        after the Licensed Application was made available to the user. The
        statutory periods of limitation given by law apply for users who are
        consumers.
      </Typography>
      <br />{" "}
      <Typography id="product-claims" variant="h6" gutterBottom>
        10. PRODUCT CLAIMS
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whiteswan Identity Security , Corp and the End-User acknowledge that
        Whiteswan Identity Security , Corp, and not the Services, is responsible
        for addressing any claims of the End-User or any third party relating to
        the Licensed Application or the End-User’s possession and/or use of that
        Licensed Application, including, but not limited to: <br />
        <br />
        (i) product liability claims; <br />
        <br />
        (ii) any claim that the Licensed Application fails to conform to any
        applicable legal or regulatory requirement; and <br />
        <br />
        (iii) claims arising under consumer protection, privacy, or similar
        legislation.
      </Typography>
      <br />
      <Typography id="legal-compliance" variant="h6" gutterBottom>
        11. LEGAL COMPLIANCE
      </Typography>
      <Typography variant="body1" gutterBottom>
        You represent and warrant that You are not located in a country that is
        subject to a US Government embargo, or that has been designated by the
        US Government as a "terrorist supporting" country; and that You are not
        listed on any US Government list of prohibited or restricted parties.
      </Typography>
      <br />
      <Typography id="contact-information" variant="h6" gutterBottom>
        12. CONTACT INFORMATION
      </Typography>
      <Typography variant="body1" gutterBottom>
        For general inquiries, complaints, questions or claims concerning the
        Licensed Application, please contact:
        <br />
        <br /> Vinay Mamidi 333 W San Carlos St Suit 600 c/o Whiteswan Identity
        Security , San Jose, CA 95110, USA San Jose, CA 95110 United States
        vmamidi@whiteswansecurity.com
      </Typography>
      <br />
      <Typography id="termination" variant="h6" gutterBottom>
        13. TERMINATION
      </Typography>
      <Typography variant="body1" gutterBottom>
        The license is valid until terminated by Whiteswan Identity Security ,
        Corp or by You. Your rights under this license will terminate
        automatically and without notice from Whiteswan Identity Security , Corp
        if You fail to adhere to any term(s) of this license. Upon License
        termination, You shall stop all use of the Licensed Application, and
        destroy all copies, full or partial, of the Licensed Application.
      </Typography>
      <br />
      <Typography id="third-party-terms" variant="h6" gutterBottom>
        14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whiteswan Identity Security , Corp represents and warrants that
        Whiteswan Identity Security , Corp will comply with applicable
        third-party terms of agreement when using Licensed Application.
        <br />
        <br /> In Accordance with Section 9 of the "Instructions for Minimum
        Terms of Developer's End-User License Agreement," subsidiaries shall be
        third-party beneficiaries of this End User License Agreement and — upon
        Your acceptance of the terms and conditions of this License Agreement,
        will have the right (and will be deemed to have accepted the right) to
        enforce this End User License Agreement against You as a third-party
        beneficiary thereof.
      </Typography>
      <br />
      <Typography id="intellectual-property-rights" variant="h6" gutterBottom>
        15. INTELLECTUAL PROPERTY RIGHTS
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whiteswan Identity Security , Corp and the End-User acknowledge that, in
        the event of any third-party claim that the Licensed Application or the
        End-User's possession and use of that Licensed Application infringes on
        the third party's intellectual property rights, Whiteswan Identity
        Security , Corp, and not the Services, will be solely responsible for
        the investigation, defense, settlement, and discharge or any such
        intellectual property infringement claims.
      </Typography>
      <br />
      <Typography id="applicable-law" variant="h6" gutterBottom>
        16. APPLICABLE LAW
      </Typography>
      <Typography variant="body1" gutterBottom>
        This License Agreement is governed by the laws of the State of
        California excluding its conflicts of law rules.
      </Typography>
      <br />
      <Typography id="miscellaneous" variant="h6" gutterBottom>
        17. MISCELLANEOUS
      </Typography>
      <Typography variant="body1" gutterBottom>
        17.1 If any of the terms of this agreement should be or become invalid,
        the validity of the remaining provisions shall not be affected. Invalid
        terms will be replaced by valid ones formulated in a way that will
        achieve the primary purpose.
        <br />
        <br /> 17.2 Collateral agreements, changes and amendments are only valid
        if laid down in writing. The preceding clause can only be waived in
        writing.
      </Typography>
    </Box>
  );
};

export default ServiceTerms;
