import React, { useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { myLocalStorage } from "./StorageHelper";
import { useNavigate } from "react-router-dom";
import { useKeycloakStore } from "../services/UserService";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
`;

function Loader() {
  const navigate = useNavigate();
  const { local_kc } = useKeycloakStore();
  const role = myLocalStorage.getItem("role");

  const access_token = myLocalStorage.getItem("access_token");

  useEffect(() => {
    if (access_token || local_kc?.authenticated) {
      if (role !== "TENANT_USER") navigate("/dashboard/overview");
      else navigate("/endpoints");
    }
  }, [access_token, local_kc, navigate, role]);

  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export default Loader;
