import React from "react";
import {
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from "@mui/material";

const DeviceListTable = ({ os, deviceList, selectedGroup }) => {
  const groupDevices = deviceList?.filter((device) =>
    device.categories?.some(
      (category) =>
        category.categoryName.toLowerCase() ===
        selectedGroup?.name.toLowerCase(),
    ),
  );

  const showTableBody =
    selectedGroup &&
    selectedGroup.operationSystem === os &&
    groupDevices?.length;

  return (
    <TableContainer
      sx={{
        marginTop: 2.5,
      }}
      component={Paper}
    >
      <Table
        aria-label="simple table"
        sx={{
          height: "fit-content",
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
      >
        <TableHead sx={{ backgroundColor: "#233044" }}>
          <TableRow>
            <TableCell sx={{ color: "white", width: 60 }} align="center">
              <Typography fontSize={14} fontWeight={600} width={60}>
                Sl No
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                color: "white",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography fontSize={14} fontWeight={600}>
                Devices
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                color: "white",
                textOverflow: "ellipsis",
              }}
            >
              <Typography fontSize={14} fontWeight={600}>
                OS
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showTableBody
            ? groupDevices.map((device, i) => (
                <TableRow key={device.id}>
                  <TableCell component="td" scope="row" align="center">
                    {i + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: 540,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography
                      variant="body1"
                      maxWidth={300}
                      fontSize={15}
                      sx={{ wordWrap: "break-word" }}
                    >
                      {device.dNSHostName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={15}
                      sx={{ wordWrap: "break-word" }}
                    >
                      {device.OperatingSystem}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeviceListTable;
