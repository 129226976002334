import React, { useEffect } from "react";
import {
  Box,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const useStyles = makeStyles({
  root: {
    height: "400px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});
const TopResources = ({ data, limit }) => {
  let classes = useStyles();
  useEffect(() => {}, []);
  const groupedData = data.reduce((acc, item) => {
    const { resourceName } = item;
    acc[resourceName] = (acc[resourceName] || 0) + 1;

    return acc;
  }, {});

  const sortedData = Object.entries(groupedData)
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  const sliceData = Object.fromEntries(
    Object.entries(sortedData).slice(0, limit),
  );

  const getPercentage = (actualData, obj) => {
    let sumValue = Object.values(obj).reduce((acc, curr) => acc + curr, 0);
    return Math.round((actualData / sumValue) * 100) + "%";
  };
  const extractFolderPathOrUrl = (str) => {
    const regex = /^(.*[\\/])/;
    const match = str.match(regex);
    return match ? match[1] : getURLValid(str);
  };
  const getURLValid = (str) => {
    const regex = /\b\.com\b/;
    const match = str.match(regex);
    return match ? match[1] : null;
  };

  return (
    <>
      <div className={classes.root}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {Object.entries(sliceData).map(([key, value], index) => (
            <div key={index}>
              <Box
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ListItem sx={{ p: 5 }}>
                  <ListItemIcon>
                    {extractFolderPathOrUrl(key) === ".com" ? (
                      <LinkOutlinedIcon />
                    ) : extractFolderPathOrUrl(key) === null ? (
                      <ComputerOutlinedIcon />
                    ) : (
                      <FolderOutlinedIcon />
                    )}
                  </ListItemIcon>

                  <ListItemText>
                    <Typography variant="body1" fontWeight="bold">
                      {key}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <Box
                  maxWidth={100}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    spacing={2}
                  >
                    <Stack spacing={2} sx={{ flex: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseInt(value)}
                      />
                    </Stack>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      {getPercentage(value, sliceData)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {index < Object.keys(sliceData).length - 1 && <Divider />}
            </div>
          ))}
        </List>
        {/*  <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="100%"
      /> */}
      </div>
    </>
  );
};

export default TopResources;
