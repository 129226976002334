import React, { useState, useEffect } from "react";
import axios from "axios";
import { Checkbox, FormControlLabel } from "@mui/material";
import { NGROK } from "../../../../APIs";

const UserSelection = ({
  activeUsersList,
  setActiveUsersList,
  activeDevicesList,
  selectedTenantName,
}) => {
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    const getUsersList = async () => {
      const fetchUsers = async (computerId) => {
        const response = await axios.get(
          `${NGROK}/api/${selectedTenantName}/computer-users/${computerId}`,
        );
        return response.data.content;
      };
      const usersTestArray = await Promise.all(
        activeDevicesList.map((device) => fetchUsers(device.id)),
      );
      setUsersList(usersTestArray.flat());
    };

    getUsersList();
  }, [activeDevicesList, selectedTenantName]);

  return (
    <>
      {usersList.length
        ? usersList.map((user) => (
            <FormControlLabel
              key={user.id}
              control={
                <Checkbox
                  value={activeUsersList.includes(user)}
                  onChange={() => {
                    if (!activeUsersList.includes(user)) {
                      setActiveUsersList((prev) => [...prev, user]);
                    } else {
                      setActiveUsersList((prev) =>
                        prev.filter((activeUser) => activeUser.id !== user.id),
                      );
                    }
                  }}
                />
              }
              label={user.cn}
            />
          ))
        : null}
    </>
  );
};

export default UserSelection;
